import { useApp } from '@app/lib/hooks/app'
import pxToRem from '@app/ui/utils/pxToRem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { FC, PropsWithChildren } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { GenericCardProps } from './types'

const Container = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #e8e8e866;
  border: 1px solid #e2ecf2;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  ${media.desktop`
    padding-left: ${pxToRem(20)};
    padding-right: ${pxToRem(20)};
    padding-top: ${pxToRem(10)};
    padding-bottom: ${pxToRem(10)};
  `}
`

const CardHeaderWrapper = styled.div`
  padding-left: ${pxToRem(14)};
  padding-right: ${pxToRem(14)};
  padding-top: ${pxToRem(11)};
  padding-bottom: ${pxToRem(10.5)};
  border-bottom: 1px solid #b7d4e54d;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  ${media.desktop`
    padding-left: 0;
    padding-right: 0;
    padding-top: ${pxToRem(15.5)};
    padding-bottom: ${pxToRem(15.5)};
  `}
`

const CardHeaderTitle = styled.div`
  color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 900;
  font-size: ${pxToRem(15)};
  letter-spacing: 0px;
  margin-right: ${pxToRem(5)};
  ${media.desktop`
    font-size: ${pxToRem(18)};
  `}
`

const CardHeaderLink = styled.a`
  text-decoration: underline;
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  letter-spacing: 0px;
  color: #1a4469;
  &:hover {
    color: #ff6820;
  }
  display: flex;
  align-items: center;
`

const CardHeaderLinkIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.default};
  ${media.desktop`
    font-size: ${pxToRem(18)};
    color: #FF6820;
  `}
`

const CardHeaderLinkText = styled.span`
  margin-left: ${pxToRem(5)};
  ${media.desktop`
    margin-left: ${pxToRem(8)};
  `}
`

const CardBodyWrapper = styled.div`
  padding-top: ${pxToRem(15.5)};
  padding-bottom: ${pxToRem(20)};
  padding-left: ${pxToRem(14)};
  padding-right: ${pxToRem(14)};
  ${media.desktop`
    padding-top: ${pxToRem(22.5)};
    padding-bottom: ${pxToRem(22.5)};
    padding-left: ${pxToRem(0)};
    padding-right: ${pxToRem(0)};
  `}
`

const BottomLinkWrapper = styled.div`
  padding: 0 ${pxToRem(14)} ${pxToRem(20)} ${pxToRem(23)};

  ${CardHeaderLink} {
    ${CardHeaderLinkIcon} {
      color: #ff6820;
      font-size:  ${pxToRem(20)};
      padding-right: ${pxToRem(5)};
    }

    ${CardHeaderLinkText} {
      font-size: ${pxToRem(16)}l
    }
  }
`

const GenericCard: FC<PropsWithChildren<GenericCardProps>> = ({
  headerTitle,
  headerLink,
  faqCard,
  children,
}) => {
  const { isDesktop } = useApp()

  return (
    <Container>
      <CardHeaderWrapper>
        <CardHeaderTitle>{headerTitle}</CardHeaderTitle>
        {headerLink && (isDesktop || !headerLink.hideOnMobile) && (
          <Link scroll={headerLink?.scroll ?? false} href={headerLink.url} passHref legacyBehavior>
            <CardHeaderLink>
              <CardHeaderLinkIcon icon={headerLink.icon} />
              <CardHeaderLinkText>{headerLink.text}</CardHeaderLinkText>
            </CardHeaderLink>
          </Link>
        )}
      </CardHeaderWrapper>
      <CardBodyWrapper>{children}</CardBodyWrapper>
      {headerLink && (!isDesktop && faqCard) && (
        <BottomLinkWrapper>
          <Link scroll={headerLink?.scroll ?? false} href={headerLink.url} passHref legacyBehavior>
            <CardHeaderLink>
              <CardHeaderLinkIcon icon={headerLink.icon} />
              <CardHeaderLinkText>{headerLink.text}</CardHeaderLinkText>
            </CardHeaderLink>
          </Link>
        </BottomLinkWrapper>
        )}
    </Container>
  )
}

export default GenericCard
