import pxToRem from '@app/ui/utils/pxToRem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useCallback } from 'react'
import styled from 'styled-components'
import { CtaItemInterface } from './types'
import Link from '../Link'
import { useDispatch } from 'react-redux'
import { closeMobileNavMenu } from '@app/store/slices/app-slice'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${pxToRem(25)};
  padding-right: ${pxToRem(25)};
  padding-top: ${pxToRem(25.5)};
  padding-bottom: ${pxToRem(25.5)};
  gap: ${pxToRem(15)};

  & > a > div {
    transition: all 0.25s linear;
  }

  /* The rule below is to fix a bug where having more than one link directly adjacent. */
  /* The other style is meant to stop links sitting right next to each, but it's catching these CTA cards */
  a {
    margin-left: 0;
  }
`

const CtaItemWrapper = styled.div`
  border: 1px solid #c5d9e580;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  width: ${pxToRem(260)};
  overflow: hidden;
  &:hover {
    background-color: #ecf3fa;
  }
`

const CtaImageWrapper = styled.div`
  height: ${pxToRem(148)};
  border: 1.5px solid #c5d9e580;
  border-style: solid solid none solid;
  border-radius: 7px 7px 0px 0px;
  background-color: #f3f8fc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const CtaContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${pxToRem(21)};
  padding-right: ${pxToRem(21)};
  padding-top: ${pxToRem(25)};
  padding-bottom: ${pxToRem(25)};
  cursor: pointer;
  background-color: white;
`

const CtaTitle = styled.div`
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
`

const CtaAction = styled.div`
  margin-top: ${pxToRem(5)};
  color: ${props => props.theme.colors.info};
  display: flex;
  align-items: center;
  ${CtaItemWrapper}:hover & {
    color: #ff6820;
  }
`

const CtaActionImage = styled.img`
  object-fit: cover;
`

const CtaActionIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.normal};
`

const CtaActionText = styled.div`
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  margin-left: ${pxToRem(10)};
  text-decoration: underline;
`

interface CtaItemsProps {
  ctaItems: CtaItemInterface[]
}

const CtaItems: FC<CtaItemsProps> = ({ ctaItems }) => {
  const items = ctaItems?.length > 2 ? ctaItems.slice(0, 2) : ctaItems
  const dispatch = useDispatch()

  const closeMenu = useCallback(() => {
    dispatch(closeMobileNavMenu())
  }, [dispatch])

  return (
    <Container>
      {items?.map((ctaItem, index) => (
        <Link href={ctaItem?.ctaLink ?? ''} key={index} passHref legacyBehavior target={ctaItem?.ctaLinkTarget} onClick={closeMenu}>
          <CtaItemWrapper>
            <CtaImageWrapper>
              <CtaActionImage src={ctaItem.ctaImage} />
            </CtaImageWrapper>
            <CtaContentWrapper>
              <CtaTitle>{ctaItem.ctaTitle}</CtaTitle>
              <CtaAction>
                {ctaItem.isDownload ? (
                  <>
                    <CtaActionIcon icon={['far', 'arrow-to-bottom']} />
                    <CtaActionText>Download</CtaActionText>
                  </>
                ) : (
                  <>
                    <CtaActionIcon icon={['far', 'long-arrow-right']} />
                    <CtaActionText>Learn more</CtaActionText>
                  </>
                )}
              </CtaAction>
            </CtaContentWrapper>
          </CtaItemWrapper>
        </Link>
      ))}
    </Container>
  )
}

export default CtaItems
