// Converts a typical WP post slug to the desired branch prefix slug
// e.g. '/sa-example/' -> '/sa/example/'

import { getActiveBranchFromSession } from '@app/lib/utils'

// Or if the branch prefix is not specified (postType !== 'Page') add the prefix
export const slugToBranchPrefix = (slug: string, postType: string, session: any) => {
  if (!slug || typeof slug !== 'string') return '#'

  if (slug && slug.includes('-') && postType === 'Page') {
    const slugArr = slug.split('-')
    const branchPrefix = slugArr[0]
    return slug.replace(branchPrefix + '-', branchPrefix + '/')
  } else if (slug?.includes('https://') || slug?.includes('http://')) {
    return slug
  } else {
    const activeBranch = getActiveBranchFromSession(session)

    if (!activeBranch) return '#'

    return activeBranch.toLowerCase() ? `/${activeBranch.toLowerCase()}${slug}` : slug
  }
}
