import { useApp } from '@app/lib/hooks/app'
import pxToRem from '@app/ui/utils/pxToRem'
import Link from 'next/link'
import { FC } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import ButtonLink from '../ButtonLink'
import { GenericCTAProps } from './types'

const BackgroundImageWrapper = styled.div`
  border-radius: 7px 7px 0 0;
  max-height: ${pxToRem(320)};
  ${media.desktop`
    border-radius: 7px 0 0 7px;
    width: 68%;
  `}
`

const BackgroundImage = styled.img`
  border-radius: 7px 7px 0 0;
  width: 100%;
  height: 100%;
  max-height: inherit;
  object-fit: cover;
  ${media.desktop`
    border-radius: 7px 0 0 7px;
  `}
`

const BodyWrapper = styled.div`
  padding-left: ${pxToRem(13)};
  padding-right: ${pxToRem(13)};
  padding-top: ${pxToRem(16)};
  padding-bottom: ${pxToRem(16)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.desktop`
    width: ${pxToRem(367)};
    padding-left: ${pxToRem(20)};
    padding-right: ${pxToRem(20)};
  `}
`

const Title = styled.div`
  font-size: ${pxToRem(15)};
  letter-spacing: -0.05px;
  color: ${props => props.theme.colors.primary};
  font-weight: 700;
  line-height: 1.55555556;
  ${media.desktop`
    font-size: ${pxToRem(18)};
  `}
`

const ButtonWrapper = styled.div`
  margin-top: ${pxToRem(15)};
`

const Container = styled.div<{
  isDesktop: boolean
}>`
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #e8e8e866;
  border: 1px solid #e2ecf2;
  border-radius: 7px;
  &:not(:last-child) {
    margin-bottom: ${pxToRem(20)}
  }
  ${media.desktop`
    flex-direction: row;
  `}
  ${props => props.isDesktop && `
    cursor: pointer;
  `}

  &:hover {
    ${BodyWrapper} {
      ${ButtonWrapper} {
        & > a {
          background-color: #f2560b;
        }
      }
    }
  }
`

const GenericCTA: FC<GenericCTAProps> = ({
  backgroundImage,
  title,
  buttonText,
  buttonUrl,
  target,
}) => {
  const { isDesktop } = useApp()
  const opensInNewTab = target && !['_self', '_parent', '_top'].includes(target)

  if (!backgroundImage || !buttonText || !buttonUrl) {
    return null
  }

  const component = (
    <Container
      isDesktop={isDesktop}
    >
      <BackgroundImageWrapper>
        <BackgroundImage src={backgroundImage} />
      </BackgroundImageWrapper>
      <BodyWrapper>
        {title && <Title>{title}</Title>}
        <ButtonWrapper>
          <ButtonLink href={buttonUrl} target={target}>{buttonText}</ButtonLink>
        </ButtonWrapper>
      </BodyWrapper>
    </Container>
  )

  return isDesktop && !opensInNewTab ? (
    <Link href={buttonUrl} passHref legacyBehavior>
      {component}
    </Link>
  ) : component
}

export default GenericCTA
