import pxToRem from '@app/ui/utils/pxToRem'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/dist/client/router'
import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'
import { MenuItemsProps } from './types'
import { closeMobileNavMenu } from '@app/store/slices/app-slice'
import { useDispatch } from 'react-redux'
import { cleanSlashes } from '@app/ui/utils/cleanSlashes'
import { curryMenuLinkHandler } from './helpers'
import { checkLinkIsExternal } from '@app/lib/utils'
import { useLogout } from '@app/lib/hooks/useLogout'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

// Uses inheritance and a 0.5px hack to get border behavior to match
// the creative exactly, https://projects.invisionapp.com/share/R3YCJA3NTVJ#/screens/427835658

// Handle with care!
const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  &:first-of-type > div:first-child {
    border-top: 1px solid transparent;
  }
  &:first-of-type > div:first-child:hover {
    border-top: 1px solid #b7d4e54d;
  }
  &:last-of-type > div:first-child {
    border-bottom-width: 1px;
  }
`

const MenuItem = styled.div<{
  isactive: boolean
}>`
  border-bottom: 0.5px solid #b7d4e54d;
  border-top: 0.5px solid #b7d4e54d;
  padding-top: ${pxToRem(24)};
  padding-bottom: ${pxToRem(24)};
  padding-left: ${pxToRem(16)};
  padding-right: ${pxToRem(16)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 0 ${pxToRem(15)} 0;
  &:hover {
    background: rgb(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
    margin: 0;
    padding-left: ${pxToRem(31)};
    padding-right: ${pxToRem(31)};
  }
  ${props =>
    props.isactive &&
    `
    background: rgb(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
  `}
`

const MenuItemLink = styled.div`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
`

const MenuIcon = styled(FontAwesomeIcon) <{
  isactive: boolean
}>`
  ${MenuItem}:hover & {
    color: #ff6820;
  }
  ${props =>
    props.isactive &&
    `
    color: #FF6820;
  `}
`

const MenuText = styled.div<{
  icon?: string
}>`
  margin-left: ${props => props.icon ? pxToRem(16) : pxToRem(37)};
`

const MenuItemToggleIcon = styled(FontAwesomeIcon) <{
  isactive: boolean
}>`
  margin-right: ${pxToRem(16)};
  font-size: ${props => props.theme.fontSizes.medium};
  color: ${props => props.theme.colors.info};
  cursor: pointer;
  ${props =>
    props.isactive &&
    `
    color: #FF6820;
  `}
`

const SubItemsWrapper = styled.div<{
  isOpened: boolean
}>`
  display: flex;
  flex-direction: column;
  margin: 0 ${pxToRem(15)} 0;
  padding-left: ${pxToRem(55)};
  padding-right: ${pxToRem(55)};
  transition: all 0.15s ease;
  ${props =>
    props.isOpened
      ? `
    border-bottom: 0.5px solid #b7d4e54d;
    border-top: 0.5px solid #b7d4e54d;
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
    padding-top: ${pxToRem(3.5)};
    padding-bottom: ${pxToRem(10)};
  `
      : `
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  `}
`

const SubItemWrapper = styled.div`
  padding-top: ${pxToRem(13)};
  padding-bottom: ${pxToRem(13)};
  &:not(:last-child) {
    border-bottom: 1px solid #b7d4e54d;
  }
`

const SubItemLink = styled.a<{
  isactive: boolean
}>`
  font-size: ${props => props.theme.fontSizes.normal};
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
  display: block;
  &:hover {
    color: ${props => props.theme.colors.info};
  }
  ${props =>
    props.isactive &&
    `
    color: #00AFD9;
  `}
`

const MenuItemOutlineIcon = styled(FontAwesomeIcon)`
  margin-left: ${pxToRem(10)};
  font-size: 0.9rem;
  color: ${props => props.theme.colors.primary};
`

const LogoutText = styled.a`
  margin-left: ${pxToRem(30)};
  margin-top: ${pxToRem(24)};
  margin-bottom: ${pxToRem(12)};
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
`

const ResponsiveMenuItems: FC<MenuItemsProps> = ({
  menuItems,
  openKey,
  selectMenuItem,
}) => {
  const router = useRouter()
  const performLogout = useLogout()
  const dispatch = useDispatch()
  let absoluteIndex = 0

  return (
    <Container>
      {menuItems?.map((menuItem, index) => {
        const isExternal = checkLinkIsExternal(String(menuItem.itemUrl))
        const clickHandler = curryMenuLinkHandler(
          index,
          menuItem,
          selectMenuItem,
          isExternal,
          absoluteIndex++,
          true,
        )

        return (
          <MenuItemWrapper key={index}>
            <MenuItem
              isactive={index === openKey}
              onClick={(event) => {
                clickHandler(event)
                if (menuItem?.subItems && menuItem?.subItems?.length === 0) {
                  dispatch(closeMobileNavMenu())
                }
              }}
            >
              <MenuItemLink>
                {menuItem.itemIcon && (
                  <MenuIcon
                    icon={['far', menuItem.itemIcon as IconName]}
                    isactive={index === openKey}
                  />
                )}
                <MenuText icon={menuItem.itemIcon}>
                  {menuItem.itemCustomLabel ? menuItem.itemCustomLabel : menuItem.itemLabel}
                  {menuItem?.itemUrl instanceof URL && (
                    <MenuItemOutlineIcon icon={['far', 'external-link']} />
                  )}
                </MenuText>
              </MenuItemLink>
              {menuItem.subItems && menuItem.subItems.length > 0 && (
                <>
                  {openKey === index ? (
                    <MenuItemToggleIcon
                      icon="minus"
                      isactive={index === openKey}
                    />
                  ) : (
                    <MenuItemToggleIcon
                      icon="plus"
                      isactive={index === openKey}
                    />
                  )}
                </>
              )}
            </MenuItem>
            {menuItem.subItems && menuItem.subItems.length > 0 && (
              <SubItemsWrapper isOpened={openKey === index}>
                {menuItem.subItems.map((subItem, subItemIndex) => {
                  const isExternal = checkLinkIsExternal(String(subItem.itemUrl))
                  const subClickHandler = curryMenuLinkHandler(
                    index,
                    subItem,
                    selectMenuItem,
                    isExternal,
                    absoluteIndex++,
                  )

                  return (
                    <SubItemWrapper key={subItemIndex} onClick={() => dispatch(closeMobileNavMenu())}>
                      <Link href={subItem.itemUrl} passHref legacyBehavior>
                        <SubItemLink
                          isactive={cleanSlashes(subItem.itemUrl as string) === router.asPath}
                          onClick={subClickHandler}
                        >
                          {subItem.itemCustomLabel ? subItem.itemCustomLabel : subItem.itemLabel}
                          {subItem?.itemUrl instanceof URL && (
                            <MenuItemOutlineIcon icon={['far', 'external-link']} />
                          )}
                        </SubItemLink>
                      </Link>
                    </SubItemWrapper>
                  )
                })}
              </SubItemsWrapper>
            )}
          </MenuItemWrapper>
        )
      })}
      <LogoutText onClick={() => {
        performLogout().then(() => {
          router.replace('/login')
        })
      }}>
        Log out
      </LogoutText>
    </Container>
  )
}

export default ResponsiveMenuItems
