import { useAppSelector } from '@app/store'
import { useEffect, useState } from 'react'

export const useWidgetsLoaded = () => {
  const [widgetsLoaded, setWidgetsLoaded] = useState<boolean>(false)
  const [hasTimedOut, setHasTimedOut] = useState<boolean>(false)

  const sliceNames = [
    'campaigns',
    'discounts',
    'employers',
    'events',
    'forms',
    'news',
    'notices',
    'ebas',
  ]

  const statuses = sliceNames.map(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    sliceName => (useAppSelector(state => state?.[sliceName]))?.status !== 'loading'
  )

  useEffect(() => {
    if (!hasTimedOut) setWidgetsLoaded(!statuses.some(val => !val))
  }, [hasTimedOut, statuses])

  useEffect(() => {
    setTimeout(() => {
      setHasTimedOut(true)
      setWidgetsLoaded(true)
    }, 20000)
  }, [])

  return {
    widgetsLoaded
  }
}
