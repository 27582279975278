import { FC, SyntheticEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useRouter } from 'next/dist/client/router'
import { useDispatch } from 'react-redux'
import { openMobileNavMenu } from '@app/store/slices/app-slice'
import pxToRem from '@ui/utils/pxToRem'
import { MenuItemInterface } from '../PrimaryNavMenu/types'
import { checkLinkIsExternal } from '@app/lib/utils'

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ecf3fa 0% 0% no-repeat padding-box;
  border: 1px solid #d9e6ed;
  opacity: 1;
  z-index: 10;
  ${media.desktop`
    display: none;
  `}
`

const Menu = styled.div<{
  col: number
}>`
  display: grid;
  grid-template-columns: repeat(${props => props.col}, minmax(0, 1fr));
  padding-top: ${pxToRem(10)};
  padding-bottom: ${pxToRem(9)};
`

const MenuItem = styled.a<{
  isActive?: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.link.color};
  padding-left: ${pxToRem(8)};
  padding-right: ${pxToRem(8)};
  margin: 0;

  &:hover {
    color: #00afd9;
  }
  ${props =>
    props.isActive &&
    `
    color: #00AFD9;
  `}
  &:not(:last-child) {
    border-right: 1px solid #b7d4e54d;
  }
`

const MenuIcon = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.medium};
  line-height: 1.222222222;
`

const MenuText = styled.span`
  font-size: ${(props: any) => props.theme.fontSizes.small};
  line-height: 1.2;
  font-weight: 700;
`

export type MobileSubMenuProps = {
  primaryLinks: MenuItemInterface[]
}

const MobileSubMenu: FC<MobileSubMenuProps> = ({ primaryLinks }) => {
  const links =
    primaryLinks?.length > 4 ? primaryLinks.slice(0, 4) : primaryLinks
  const dispatch = useDispatch()
  const location = useRouter()
  const [isNearBottom, setIsNearBottom] = useState(false)
  const router = useRouter()

  const isActive = (pathname: string) => {
    return location.pathname === pathname
  }

  useEffect(() => {
    window.onscroll = function () {
      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight - 100 && window.innerHeight > 1200) {
        setIsNearBottom(true)
      } else {
        setIsNearBottom(false)
      }
    }
  }, [])

  return (
    <Container hidden={isNearBottom}>
      <Menu col={(links?.length ?? 0) + 1}>
        {links?.map((primaryLink, index) => {
          const isExternal = checkLinkIsExternal(String(primaryLink.itemUrl))
          const curriedHandler = ((isExternalFlag) =>
            isExternalFlag ?
              // Curry two different handlers depending on whether the links is internal, or external
              (event: SyntheticEvent) => {
                event.preventDefault()
                window.open(primaryLink.itemUrl ? String(primaryLink.itemUrl) : 'about:blank')
              } :
              (event: SyntheticEvent) => {
                event.preventDefault()
                router.push(String(primaryLink.itemUrl))
              }
          )(isExternal)

          return (
            <MenuItem
              key={index}
              isActive={isActive(String(primaryLink.itemUrl))}
              onClick={(event) => {
                curriedHandler(event)
                window.scrollTo(0, 0)
              }}
              href={primaryLink.itemUrl ? String(primaryLink.itemUrl) : '3'}
            >
              <MenuIcon>
                {primaryLink.itemIcon && <FontAwesomeIcon
                  icon={
                    {
                      prefix: 'far',
                      iconName: primaryLink.itemIcon,
                    } as IconProp
                  }
                />}
              </MenuIcon>
              <MenuText>{primaryLink.itemCustomLabel ? primaryLink.itemCustomLabel : primaryLink.itemLabel}</MenuText>
            </MenuItem>
          )
        })}
        <MenuItem onClick={() => dispatch(openMobileNavMenu())}>
          {links?.length && links.length > 0 ?
            <>
              <MenuIcon>
                <FontAwesomeIcon
                  icon={{ prefix: 'fas', iconName: 'bars' }}
                />
              </MenuIcon>
              <MenuText>More</MenuText>
            </>
            :
            <>
              <MenuIcon>
                <FontAwesomeIcon
                  icon={{ prefix: 'fas', iconName: 'bars' }}
                />
              </MenuIcon>
              <MenuText>Menu</MenuText>
            </>}
        </MenuItem>
      </Menu>
    </Container>
  )
}

export default MobileSubMenu
