import { AppState } from '@app/store'
import { slugToBranchPrefix } from '@ui/utils/slugToBranchPrefix'
import { getActiveBranchFromSession } from '@app/lib/utils'
import { useUser } from '@app/lib/hooks/useUser'

export const selectAskAQuestionLink = (session: ReturnType<typeof useUser>['session']) => (state: AppState) => {
    const uri = state.app?.branchSettings.issuesAtWorkAskAQuestion?.uri

    return uri ? slugToBranchPrefix(uri, 'Page', session) : `/${getActiveBranchFromSession(session)?.toLowerCase()}/ask-a-question/`
}
