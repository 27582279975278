import Link from 'next/link'
import { FC } from 'react'
import SlickSlider from '@ui/components/SlickSlider'
import { EventInterface } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import pxToRem from '@app/ui/utils/pxToRem'
import { media } from 'styled-bootstrap-grid'
import { faTicketAlt } from '@fortawesome/pro-regular-svg-icons'
import { FreeBadge, FreeBadgeText } from './'
import { slugToBranchPrefix } from '@app/ui/utils/slugToBranchPrefix'
import { useUser } from '@app/lib/hooks/useUser'
import React from 'react'
import { useAppSelector } from '@app/store'

const EventWrapper = styled.div<{ $disabled?: boolean }>`
  position: relative;
  display: flex;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 7px;
  cursor: ${props => props.$disabled ? 'initial' : 'pointer' };
  &:hover {
    background-color: #ECF3FA;
  }
  ${media.desktop`
    display: none;
  `}
`

const EventImageWrapper = styled.div`
  flex: 1;
  min-width: ${pxToRem(99)};
  max-width: ${pxToRem(99)};
  overflow: hidden;
`
const EventImage = styled.img`
  min-width: ${pxToRem(99)};
  height: ${pxToRem(127)};
  object-fit: cover;
  border-radius: 7px 0px 0px 7px;
`

const EventTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: ${pxToRem(13)};
  padding-right: ${pxToRem(13)};
`

const EventDate = styled.div<{ $isCancelled?: boolean }>`
  line-height: 1;
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.$isCancelled ? '#d23d4b' : '#898989'};
  margin-bottom: ${pxToRem(7)};
`

const EventTitle = styled.div`
  font-size: ${pxToRem(15)};
  font-weight: 700;
  letter-spacing: -0.04px;
  color: ${props => props.theme.colors.primary};
  margin-bottom: ${pxToRem(15)};
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const EventLink = styled.a`
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  letter-spacing: 0px;
  color: ${props => props.theme.colors.info};
  display: flex;
  align-items: center;
  line-height: 1;
  ${EventWrapper}:hover & {
    color: ${props => props.theme.colors.warning};
  }
`

const EventLinkIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.normal};
`

const EventLinkText = styled.span`
  margin-left: ${pxToRem(7)};
  text-decoration: underline;
`

interface MobileUpcomingEventsProps {
  eventItems: EventInterface[]
}

// eslint-disable-next-line react/display-name
const EventCard: FC<{ eventItem: EventInterface } & { onClick?: any }> = React.forwardRef(({ eventItem, onClick }, ref) => {
    const { branchSettings } = useAppSelector(state => state.app)

    return <EventWrapper
      onClick={onClick}
      ref={ref as React.MutableRefObject<HTMLDivElement>}
      $disabled={eventItem.isCancelled}
    >
      <EventImageWrapper>
        <EventImage src={eventItem.eventImage ?? branchSettings?.eventsListingFallbackFeaturedImage?.mediaItemUrl} />
      </EventImageWrapper>
      <EventTitleWrapper>
        {eventItem.isFree && (
          <FreeBadge>
            <FreeBadgeText>Free Event</FreeBadgeText>
          </FreeBadge>
        )}
        <EventDate $isCancelled={eventItem.isCancelled}>
          {eventItem.eventStartDate}
          {eventItem.eventStartDate?.length > 0 && eventItem.eventEndDate?.length > 0 ? <> - </> : null}
          {eventItem.eventEndDate}
        </EventDate>
        <EventTitle>{eventItem.eventTitle}</EventTitle>
        {!eventItem.isCancelled && <EventLink>
          <EventLinkIcon icon={faTicketAlt} />
          <EventLinkText>Learn more</EventLinkText>
        </EventLink>}
      </EventTitleWrapper>
    </EventWrapper>
  })


const MobileUpcomingEvents: FC<MobileUpcomingEventsProps> = ({ eventItems }) => {
  const { session } = useUser()

  return (
    <SlickSlider>
      {eventItems?.map((eventItem, index) => (
        <div key={index}>
          {!eventItem.isCancelled ?
            <Link href={slugToBranchPrefix(eventItem.eventLink, 'event', session) ?? '#'} passHref legacyBehavior>
              <EventCard eventItem={eventItem} />
            </Link> :
            <EventCard eventItem={eventItem} />}
        </div>
      ))}
    </SlickSlider>
  )
}

export default MobileUpcomingEvents
