import { FC } from 'react'
import styled from 'styled-components'
import { NoOverlaySpinner } from '../Spinner'

const Container = styled.div<{ $show: boolean }>`
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  opacity: ${props => props.$show ? 1 : 0};
  pointer-events: ${props => props.$show ? 'all' : 'none' };
  position: absolute;
  transition: opacity 0.5s ease;
  width: 100%;
  z-index: 10;
`

const CustomSpinner = styled(NoOverlaySpinner)`
  position: sticky;
  top: max(50%, 144px);
`

export const LoaderOverlay: FC<{ show: boolean }> = ({ show = false }) => {
  return (
    <Container $show={show}>
      <CustomSpinner />
    </Container>
  )
}
