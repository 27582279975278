import { FC } from 'react'
import styled, { keyframes } from 'styled-components'

const spinAnimation = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
`

const Overlay = styled.div`
  background-color: #FFFFFF;
  height: 100vh;
  position: fixed;
  text-align: center;
  width: 100vw;
`

const Icon = styled.span`
  top: 50%;
  border: 1px solid red;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  margin: 0 auto;
  display: block;
  position: relative;
  border: 3px solid #E5E5E5;
  margin-top: -25px;
  &:before {
    position: absolute;
    content: "";
    box-sizing: content-box;
    border-color: #001f3d rgba(0,0,0,0) rgba(0,0,0,0);
    border-radius: 100%;
    border-style: solid;
    border-width: 3px;
    display: block;
    height: 100%;
    width: 100%;
    top: -3px;
    left: -3px;
    animation: ${spinAnimation} .9s infinite cubic-bezier(0.445, .03, .55, .95);
  }
`

const NoOverlayIcon = styled(Icon)`
  margin-top: 30px;
  margin-bottom: 60px;
`

const OnlyIcon = styled(Icon)`
  margin-top: 0;
  margin-bottom: 0;
`

const Spinner: FC = () => {
  return <Overlay><Icon /></Overlay>
}

const NoOverlaySpinner: FC = ({...props}) => {
  return <NoOverlayIcon {...props} />
}

const OnlySpinner: FC = () => {
  return <OnlyIcon />
}

export { Spinner, NoOverlaySpinner, OnlySpinner }
