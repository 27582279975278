import { ThemeConfig } from '@app/ui/theme/type'
import { excludeProps } from '@app/ui/utils/shouldForwardProp'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

export type TextProps = {
  as?: keyof HTMLElementTagNameMap,
  children?: any
  className?: string,
  size?: keyof ThemeConfig['fontSizes'],
  variant?: string,
  color?: keyof ThemeConfig['colors'],
  align?: 'left' | 'center' | 'right',
  fontStyle?: 'italic',
  weight?: CSSProperties['fontWeight'],
  transform?: CSSProperties['textTransform'],
  decoration?: CSSProperties['textDecoration']
}

const Text = ({ children, className, as = 'span', size = 'default', variant, color, ...props }: TextProps) => {
  return React.createElement(
    as,
    {
      style: {
        textDecoration: ''
      },
      className,
      ...props
    },
    children,
  )
}

const TextStyled = styled(Text).withConfig({
  shouldForwardProp: excludeProps([
    // 'size', 'color', 'variant', 'align', 'fontStyle',
    // 'transform', 'decoration'
  ])
}) <TextProps>`
  color: ${p => (p.color && p.theme.colors[p.color]) || 'inherit'};
  ${p => p.weight && `
    font-weight: ${p.weight};
  `}
  ${p => p.decoration && `
    text-decoration: ${p.decoration};
  `}
  ${p => p.transform && `
    text-transform: ${p.transform};
  `}
  ${p => p.align && `
    text-align: ${p.align};
  `}
  ${p => p.size && `
    font-size: ${p.theme.fontSizes[p.size]};
  `}
`

export default TextStyled
