import BaseLink, { LinkProps as BaseLinkProps } from 'next/link'
import styled from 'styled-components'
import Text, { TextProps } from '../Text'
import pxToRem from '@app/ui/utils/pxToRem'

export type LinkProps = BaseLinkProps & {
  children: any
  className?: string,
  textProps?: TextProps
  fontSize?: number
}

export const LinkStyled = styled(Text)<{
  fontSize?: number
  onClick?: () => void
}>`
  font-size: ${(props) => pxToRem(props.fontSize as number)};
  color: #1A4469;
  &:hover, &:focus, &:active {
    color: #ff6820;
  }
  &.underline-link {
    color: #898989;
    text-decoration: underline;
  }
  & + &, & + button {
    margin-left: 1rem;
  }
`

export const StyledLink = ({ children, className, textProps, fontSize, ...props }: LinkProps) => {

  return (
    <BaseLink
      passHref legacyBehavior
      {...props}
    >
      <LinkStyled
        fontSize={fontSize}
        className={className}
        {...textProps}
        color={(textProps && textProps.color) || 'link'}
        as="a"
      >
        {children}
      </LinkStyled>
    </BaseLink>
  )
}

