//-----------------
// This component is the 'Your Award / Agreement' widget that displays on the dashboard.
// The widget is not used on the 'Your award or agreement' page.
//-----------------

import { FC } from 'react'
import PrimaryGenericCard from '@ui/components/PrimaryGenericCard'
import styled from 'styled-components'
import Button from '@ui/components/ButtonLink'
import pxToRem from '@app/ui/utils/pxToRem'
import { media } from 'styled-bootstrap-grid'
import Skeleton from 'react-loading-skeleton'
import { useAppSelector } from '@app/store'
import { IAwardIndicator as AwardIndicatorProps, Status } from '@app/store/slices/employer-slice'
import { useUser } from '@app/lib/hooks/useUser'
import { getActiveBranchFromSession } from '@app/lib/utils'

const AwardIndicatorBody = styled.div`
  display: flex;
  gap: ${pxToRem(10)};
  align-items: center;
  height: 100%;
`

const AwardIndicatorRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: ${pxToRem(12)};
  padding-bottom: ${pxToRem(10)};
  label {
    color: ${props => props.theme.colors.primary};
    font-weight: 900;
    font-size: ${props => props.theme.fontSizes.small2x};
  }
  p {
    font-size: ${props => props.theme.fontSizes.small2x};
    line-height: 1.8;
    padding-top: ${pxToRem(12)};
    padding-bottom: ${pxToRem(12)};
  }
  @media screen and (max-width: 320px) {
    label, p, a {
      font-size: ${pxToRem(13)};
    }
    a {
      padding: ${pxToRem(4)} ${pxToRem(15)};
    }
  }
`

const ButtonContainer = styled.div`
  padding-bottom: ${pxToRem(5)};
  padding-top: ${pxToRem(10)};
  a, button {
    padding: ${pxToRem(6.2)} ${pxToRem(16)} ${pxToRem(6.8)};
    font-weight: 600;
    line-height: 1;
  }
`
const AwardIndicatorLeft = styled.div`
  display: flex;
  align-items: center;
  min-width: ${pxToRem(94)};
  ${media.desktop`
    min-width: ${pxToRem(101)};
  `}
`
const AwardIndicatorImg = styled('img')`
  width: ${pxToRem(94)};
  height: 100%;
  object-fit: contain;
  ${media.desktop`
    width: ${pxToRem(101)};
    height: 100%;
  `}
`

const AltAwardIndicatorImg = styled('img')`
  width: ${pxToRem(260)};
  height: 100%;
  object-fit: contain;
  ${media.desktop`
    width: ${pxToRem(180)};
    height: 100%;
  `}
  padding-right: ${pxToRem(16)}
`

const CompactP = styled('p')`
  padding: 0.25rem 0 !important;
`

const AwardIndicator: FC<AwardIndicatorProps> = ({
  awardImage,
  title,
  subtitle,
  bodyText,
  buttonText,
  isInAlternatePosition,
}) => {
  const { payAndConditionsLoadState } = useAppSelector(state => state.employers)

  const branch = getActiveBranchFromSession(useUser().session)?.toLowerCase()

  return (
    <PrimaryGenericCard headerTitle={title} backgroundColor="#F3F8FC">
      {
        payAndConditionsLoadState === Status.LOADING ? (
          <>
            <Skeleton
              count={1}
              width={'100%'}
              height={30}
            />
            <Skeleton
              count={1}
              width={'100%'}
              height={30}
            />
            <Skeleton
              count={1}
              width={'100%'}
              height={30}
            />
            <Skeleton
              count={1}
              width={'100%'}
              height={30}
            />
          </>
        ) : (
          <AwardIndicatorBody>
            {
              awardImage &&
              <AwardIndicatorLeft>
                {!isInAlternatePosition &&
                  <AwardIndicatorImg src={awardImage} />
                }
                {isInAlternatePosition &&
                  <AltAwardIndicatorImg src={awardImage} />
                }
              </AwardIndicatorLeft>
            }
            <AwardIndicatorRight>
              <div>
                <label>{subtitle}</label>
                <CompactP>{bodyText}</CompactP>
              </div>
              <ButtonContainer>
                <Button href={`/${branch ? branch : '#'}/your-award-or-agreement/`}>{buttonText}</Button>
              </ButtonContainer>
            </AwardIndicatorRight>
          </AwardIndicatorBody>
        )
      }
    </PrimaryGenericCard>
  )
}

export default AwardIndicator
