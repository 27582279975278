import { getFileExtension, stripTrailingSlash } from '@app/lib/utils'
import pxToRem from '@app/ui/utils/pxToRem'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/dist/client/router'
import { FC } from 'react'
import styled from 'styled-components'
import { curryMenuLinkHandler } from './helpers'
import { MenuItemsProps } from './types'

const Container = styled.div`
  margin-top: ${pxToRem(31)};
  padding-right: ${pxToRem(25)};
  > * + * {
    margin-top: ${pxToRem(20)};
  }
`

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const MenuItem = styled.a<{
  isactive: boolean
}>`
  padding-top: ${pxToRem(11)};
  padding-bottom: ${pxToRem(11)};
  padding-left: ${pxToRem(25)};
  padding-right: ${pxToRem(14)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 0px 10px 10px 0px;
  }
  ${props =>
    props.isactive &&
    `
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 0px 10px 10px 0px;
  `}
`

const MenuItemLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
`

const MenuItemLabelIconWrapper = styled.div`
  display: flex;
  width: ${pxToRem(23)};
`
const MenuItemLabelIcon = styled(FontAwesomeIcon) <{
  isactive: boolean
}>`
  font-size: ${props => props.theme.fontSizes.mediumx};
  ${MenuItem}:hover & {
    color: #ff6820;
  }
  ${props =>
    props.isactive &&
    `
    color: #FF6820;
  `}
`

const MenuItemLabelText = styled.div`
  margin-left: ${pxToRem(13)};
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: 700;
`

const MenuItemOutlineIcon = styled(FontAwesomeIcon)`
  margin-left: ${pxToRem(11)};
  font-size: 0.9rem;
  color: ${props => props.theme.colors.primary};
`

const MenuItemToggleIcon = styled(FontAwesomeIcon) <{
  isactive: boolean
}>`
  font-size: ${pxToRem(15)};
  color: ${props => props.theme.colors.info};
  ${MenuItem}:hover & {
    color: #ff6820;
  }
  ${props =>
    props.isactive &&
    `
    color: #FF6820;
  `}
`

const SubItemsWrapper = styled.div<{
  isOpened: boolean
}>`
  display: flex;
  flex-direction: column;
  padding-left: ${pxToRem(37.5)};
  padding-right: ${pxToRem(39)};
  transition: all 0.15s ease;
  ${props =>
    props.isOpened
      ? `
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
    padding-top: ${pxToRem(6.5)};
    padding-bottom: ${pxToRem(6.5)};
  `
      : `
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  `}
`

const SubItemWrapper = styled.div`
  padding-top: ${pxToRem(13)};
  padding-bottom: ${pxToRem(13)};
  &:not(:last-child) {
    border-bottom: 1px solid #b7d4e54d;
  }
`

const SubItemLink = styled.a<{
  isactive: boolean
}>`
  font-size: ${props => props.theme.fontSizes.normal};
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
  display: block;
  &:hover {
    color: ${props => props.theme.colors.info};
  }
  ${props =>
    props.isactive &&
    `color: #00AFD9 !important;
  `}
`

const MenuItems: FC<MenuItemsProps> = ({
  menuItems,
  openKey,
  selectMenuItem,
}) => {
  const router = useRouter()
  let absoluteIndex = 0

  return (
    <Container>
      {menuItems?.map((menuItem, index) => {
        const isExternal = Boolean(menuItem?.itemTarget) && !['_self', '_parent', '_top'].includes(menuItem.itemTarget as string)
        const isPdf = getFileExtension(menuItem?.itemUrl as string) === 'pdf'
        const hasSubitems = menuItem.subItems && menuItem.subItems.length > 0
        const clickHandler = curryMenuLinkHandler(
          index,
          menuItem,
          selectMenuItem,
          isExternal,
          absoluteIndex++,
          true,
        )

        return (
          <MenuItemWrapper key={index}>
            <MenuItem
              isactive={index === openKey}
              onClick={clickHandler}
              href={menuItem.itemUrl && !hasSubitems ? String(menuItem.itemUrl) : '/'}
            >
              <MenuItemLabelWrapper>
                <MenuItemLabelIconWrapper>
                  {menuItem.itemIcon && (
                    <MenuItemLabelIcon
                      icon={['far', menuItem.itemIcon as IconName]}
                      isactive={index === openKey}
                    />
                  )}
                </MenuItemLabelIconWrapper>
                <MenuItemLabelText>
                  {menuItem.itemCustomLabel ? menuItem.itemCustomLabel : menuItem.itemLabel}
                  {isExternal && !isPdf && !hasSubitems && (
                    <MenuItemOutlineIcon icon={['far', 'external-link']} />
                  )}
                </MenuItemLabelText>
              </MenuItemLabelWrapper>
              {hasSubitems && (
                <>
                  {openKey === index ? (
                    <MenuItemToggleIcon
                      icon="minus"
                      isactive={index === openKey}
                    />
                  ) : (
                    <MenuItemToggleIcon
                      icon="plus"
                      isactive={index === openKey}
                    />
                  )}
                </>
              )}
            </MenuItem>
            {hasSubitems && (
              <SubItemsWrapper isOpened={openKey === index}>
                {menuItem.subItems!.map((subItem, subIndex) => {
                  const isExternal = Boolean(subItem?.itemTarget) && !['_self', '_parent', '_top'].includes(subItem.itemTarget as string)
                  const isPdf = getFileExtension(subItem?.itemUrl as string) === 'pdf'

                  const subClickHandler = curryMenuLinkHandler(
                    index,
                    subItem,
                    selectMenuItem,
                    isExternal,
                    absoluteIndex++,
                  )

                  return (
                  <SubItemWrapper key={subIndex}>
                    <SubItemLink
                      isactive={
                        stripTrailingSlash(subItem?.itemUrl as string) ===
                        stripTrailingSlash(router.asPath)
                      }
                      onClick={subClickHandler}
                      href={subItem.itemUrl ? String(subItem.itemUrl) : '/'}
                    >
                      {subItem.itemCustomLabel ? subItem.itemCustomLabel : subItem.itemLabel}
                      {isExternal && !isPdf && (
                        <MenuItemOutlineIcon icon={['far', 'external-link']} />
                      )}
                    </SubItemLink>
                  </SubItemWrapper>
                )}
                )}
              </SubItemsWrapper>
            )}
          </MenuItemWrapper>
        )
      })}
    </Container>
  )
}

export default MenuItems
