import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

export const useTransition = () => {
  const [transitionState, setTransitionState] = useState('fadeOut')
  const router = useRouter()

  const fadeOut = useCallback((url?: string) => {
    if (router.pathname === url) return
    setTransitionState('fadeOut')
  }, [router.pathname])

  const fadeIn = useCallback((url?: string) => {
    if (router.pathname === url) return
    setTransitionState('fadeIn')
  }, [router.pathname])

  useEffect(() => {
    fadeIn()

    router.events.on('routeChangeStart', fadeOut)
    router.events.on('routeChangeComplete', fadeIn)
    router.events.on('routeChangeError', fadeIn)

    return () => {
      router.events.off('routeChangeStart', fadeOut)
      router.events.off('routeChangeComplete', fadeIn)
      router.events.off('routeChangeError', fadeIn)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    transitionState,
  }
}
