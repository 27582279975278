import { MenuItemInterface } from '@app/ui/components/PrimaryNavMenu/types'
import { branchPrefixes } from './constants'
import { WORDPRESS_GRAPHQL_URL } from '@app/lib/urls'

export const stripLeadingSlash = (path: string) => path.replace(/^\/+/, '')

export const getSitemapDataFromMenu = (
  userBranch: string,
  host: string,
  proto: string,
) => (menuItem: any): any => {
  let entryPath: string = ''
  let internal = true
  const nonPrefixedTypes = [
    'page'
  ]

  const wpUrl = new URL(WORDPRESS_GRAPHQL_URL)

  try {
    const url = (new URL(menuItem.path))
    internal = (url.hostname === wpUrl.hostname)
    if (!internal) return
    entryPath = url.pathname

    if (entryPath?.includes('special_pages/awards')) {
      entryPath = 'your-award-or-agreement/'
    }
  } catch (e) {
    entryPath = menuItem.path ?? null
  }

  if (entryPath) {
    const strippedPath = stripLeadingSlash(entryPath)
    const branchPrefix = branchPrefixes.find(el => `${el}-` === (strippedPath?.match(/^\w+-/g))?.[0])
    if (branchPrefix) {
      entryPath = '/' + strippedPath.replace(/^\w+-/g, `${branchPrefix}/`)
    }
  }

  /*
  // TODO: prefix special entry types such as events, news and legal notices
  */
  if (
    !nonPrefixedTypes.includes(menuItem?.connectedNode?.node?.contentType?.node?.name)
    && !entryPath?.startsWith(`/${userBranch}`)
  ) {
    entryPath = `/${userBranch}/${entryPath}`
  }

  if (entryPath) entryPath = `${proto}://${host}${entryPath.replace('//', '/')}`

  const node = {
    loc: entryPath,
    lastmod: menuItem?.connectedNode?.node?.modified,
  }

  if (menuItem.menu) {
    return [node, ...menuItem.menu.map(getSitemapDataFromMenu(userBranch, host, proto)) ]
  }
  return node
}

export const parseMenuEntry = (userBranch: string) => (menuItem: any): any => {
  let entryPath: string = ''
  let internal = true
  const nonPrefixedTypes = [
    'page'
  ]

  const wpUrl = new URL(WORDPRESS_GRAPHQL_URL)

  try {
    const url = (new URL(menuItem.path))
    internal = (url.hostname === wpUrl.hostname)
    entryPath = internal ? url.pathname : menuItem.path

    if (entryPath?.includes('special_pages/awards')) {
      entryPath = 'your-award-or-agreement/'
    }
  } catch (e) {
    entryPath = menuItem.path ?? null
  }

  if (entryPath && internal) {
    const strippedPath = stripLeadingSlash(entryPath)
    const branchPrefix = branchPrefixes.find(el => `${el}-` === (strippedPath?.match(/^\w+-/g))?.[0])
    if (branchPrefix) {
      entryPath = '/' + strippedPath.replace(/^\w+-/g, `${branchPrefix}/`)
    }
  }

  /*
  // TODO: prefix special entry types such as events, news and legal notices
  */
  if (
    internal && !
    nonPrefixedTypes.includes(menuItem?.connectedNode?.node?.contentType?.node?.name) &&!
    entryPath?.startsWith(`/${userBranch}`)
  ) {
    entryPath = `/${userBranch}/${entryPath}`
  }

  if (entryPath && internal) entryPath = entryPath.replace('//', '/')

  const node = {
    itemIcon: menuItem?.menuSettings?.icon ?? null,
    itemUrl: entryPath,
    itemLabel: menuItem.label ?? null,
    itemCustomLabel: menuItem?.connectedNode?.node?.fcpPageOptions?.customTitle ?? null,
    itemTarget: menuItem?.target,
  }

  if (menuItem.menu) {
    return { ...node, subItems: menuItem.menu.map(parseMenuEntry(userBranch)) }
  }
  return node
}

export const createMenuLUT = (primaryMenu: MenuItemInterface[]) => {
  const transformFunc = (entry: MenuItemInterface, rootNode: MenuItemInterface, targetMenu: any[]) => {
    targetMenu.push({
      label: rootNode?.itemLabel,
      path: entry.itemUrl
    })

    if (entry.subItems && entry.subItems?.length > 0) {
      targetMenu.push(...parseSubMenu(entry.subItems, rootNode))
    }
  }

  const parseSubMenu = (subMenu: any[], rootNode: MenuItemInterface | null): any[] => {
    const unwrappedSubMenu: any[] = []

    subMenu?.forEach((entry: any) => transformFunc.call(this, entry, rootNode ?? entry, unwrappedSubMenu))

    return unwrappedSubMenu
  }

  return parseSubMenu(primaryMenu, null)
}

