import pxToRem from '@app/ui/utils/pxToRem'
import React, { useState } from 'react'
import { media } from 'styled-bootstrap-grid'
import style from 'styled-components'

interface ITabButtons {
  buttons: string[]
  changeTab: (button: string) => void
  activeTab: string
}

interface ITabsProps {
  children?: React.ReactElement[]
  fullHeightParent?: boolean
}

const TabContainer = style.div<{
  fullHeightParent?: boolean
}>`
  display: grid;
  grid-template-rows: auto 1fr;
`

const TabButtonsContainer = style.div`
  button{
    font-size: ${pxToRem(14)};
    background: transparent;
    border: none;
    outline: none;
    padding: ${pxToRem(8)} ${pxToRem(20)} ;
    cursor: pointer;
    color: #012F57;
    transition: all ease-in-out .2s;
    border-bottom: 2px solid transparent;
    border-top-right-radius: ${pxToRem(6)};
    border-top-left-radius: ${pxToRem(6)};
  }
  .active{
    background-color: #FFFFFF;
    font-weight: 600;
  }
`
const TabContent = style.div`
  background-color: #FFFFFF;
  border-radius:  ${pxToRem(6)};
  padding: ${pxToRem(30)} ${pxToRem(20)} ${pxToRem(16)};
  font-size: ${pxToRem(12)};
  height: 100%;
  width: 100%;
  ${media.desktop`
    font-size: ${pxToRem(16)};
  `}
`

export const Tab: React.FC<{
  children: React.ReactElement[],
  label: string
}> = (props) =>{
  return(
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

const TabButtons: React.FC<ITabButtons> = ({ buttons, changeTab, activeTab }) =>{
  return(
    <TabButtonsContainer>
    {buttons?.map((button: any, index) =>{
       return <button key={index} className={button === activeTab? 'active': ''} onClick={()=>changeTab(button)}>{button}</button>
    })}
    </TabButtonsContainer>
  )
}

const Tabs: React.FC<ITabsProps> = ({ children = [], fullHeightParent }) => {
  const defaultTab = children && children[0] && children[0].props && children[0].props.label
  const [ activeTab, setActiveTab] = useState<string>(defaultTab)
  const changeTab = (tab: string) => {
    setActiveTab(tab)
  }

  let buttons: string[] = React.Children?.map(children, (child: React.ReactElement) => child.props.label)
  const childrenArray = React.Children.toArray(children) as React.ReactElement[]
  const content = childrenArray.filter((child) => child?.props?.label === activeTab)?.[0]
  return (
    <TabContainer fullHeightParent={fullHeightParent}>
      <TabButtons activeTab={activeTab} buttons={buttons} changeTab={changeTab}/>
      <TabContent>{content}</TabContent>
    </TabContainer>
  )
}

export default Tabs
