export const getEbaData = (employers: any, currentEmployerId: any, payRateData: any, ebasData: any, siteSettings: any) => {
  const currentEmployer = employers?.find((element : any) => element.id === currentEmployerId) ?? {}

  const employmentStatus = currentEmployer.workplaceInfo?.employmentStatus ?? ''

  const isSalary = employmentStatus?.toLowerCase() === 'salary'

  if(isSalary) return siteSettings?.salaryEba?.awardCptFields
  
  const correctEbaIndex = determineCorrectEbaIndex(employers, currentEmployerId, payRateData, ebasData)

  return correctEbaIndex !== -1 ? ebasData[correctEbaIndex]?.awardCptFields : {}
}

export const determineCorrectEbaIndex = (employers: any, currentEmployerId: any, payRateData: any, rawEbaData: any) => {
  let correctPayAndConditionsIndex = -1
  const currentEmployerPayAndConditionsId = employers?.find((element : any) => element.id === currentEmployerId)?.payAndConditionsId

  if (Array.isArray(payRateData)) {
    for (let i = 0; i < payRateData.length; i++) {
      if (payRateData[i]?.payAndConditionsParentId === currentEmployerPayAndConditionsId) {
        correctPayAndConditionsIndex = i
        break
      }
    }
  }

  if(correctPayAndConditionsIndex === -1) {
    return -1
  }

  const currentEbaPostId = payRateData[correctPayAndConditionsIndex]?.eba?.document?.asset

  if(!currentEbaPostId) {
    return -1
  }

  let correctEbaIndex = -1

  if (Array.isArray(rawEbaData)) {
    for (let i = 0; i < rawEbaData.length; i++) {
      if (rawEbaData[i]?.databaseId === parseInt(currentEbaPostId)) {
        correctEbaIndex = i
        break
      }
    }
  }

  if(correctEbaIndex === -1) {
    return -1
  }

  return correctEbaIndex
}

export const extractDownloadAwardSectionFromRawEbaData = (rawData: any) => {
  let downloadAwardSection: any = {}

  downloadAwardSection.awardImage = rawData?.awardThumbnail?.mediaItemUrl ?? ''
  downloadAwardSection.bodyText = rawData?.bodySummaryText ?? ''
  downloadAwardSection.fullEbaUrl = rawData?.fullEbaDocument?.mediaItemUrl ?? ''
  downloadAwardSection.downloadTitle = rawData?.ebaTitle ?? 'Download your award or agreement'
  downloadAwardSection.currentFrom = rawData?.currentFrom ?? ''

  return downloadAwardSection
}

export const extractAwardIndicatorFromRawEbaData = (rawData: any) => {
  let awardIndicator: any = {}

  awardIndicator.awardImage = rawData?.awardThumbnail?.mediaItemUrl ?? ''
  awardIndicator.title = 'Your award / agreement'
  awardIndicator.subtitle = 'Your work entitlements'
  awardIndicator.bodyText = rawData?.documentSummaryText ?? ''
  awardIndicator.buttonText = 'View EBA or award'
  awardIndicator.buttonUrl = rawData?.ebaSummaryDocument?.mediaItemUrl ?? ''
  awardIndicator.fullWageTable = rawData?.fullWageTable?.mediaItemUrl ?? ''
  awardIndicator.wageTablePdfUrl = rawData?.wageTablePdf?.mediaItemUrl ?? ''

  return awardIndicator
}

export const extractAwardAccordionFromRawEbaData = (rawData: any) => {
  let awardAccordion: any = {}
  
  let accordionItems = undefined
  const awardFaqContent = rawData?.faqContent
  if (Array.isArray(awardFaqContent) && awardFaqContent.length > 0) {
    accordionItems = rawData.faqContent[0]
  }

  awardAccordion = [
    { title: 'Public holidays', bodyHtml: accordionItems?.publicHolidays ?? '' },
    { title: 'Your breaks', bodyHtml: accordionItems?.yourBreaks ?? '' },
    { title: 'Your penalty rates', bodyHtml: accordionItems?.yourPenaltyRates ?? '' },
    { title: 'Your leave entitlements', bodyHtml: accordionItems?.yourLeaveEntitlements ?? '' },
    { title: 'Your roster', bodyHtml: accordionItems?.yourRoster ?? '' },
    { title: 'Part-time hours', bodyHtml: accordionItems?.partTimeHours ?? '' },
  ]

  return awardAccordion
}