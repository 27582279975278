import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pxToRem from '@ui/utils/pxToRem'
import { media } from 'styled-bootstrap-grid'

const StyledModalBody = styled.div`
  position: relative;
  overflow-y: auto;
`

const StyledModalTitle = styled.div`
  font-size: ${pxToRem(15)};
  color: #012F57;
  font-weight: 900;
  padding-bottom: ${pxToRem(16)};
  width: 80%;
  ${media.desktop`
    font-size: ${pxToRem(18)};
  `}
`

const StyledModalHeader = styled.div`
  font-size: 25px;
`

const StyledModal = styled.div<{
  width?: string
  backgroundColor?: string
  mobileFullHeight?: boolean
}>`
  background: ${props => props.backgroundColor ? props.backgroundColor : '#F3F3F3'};
  width: 100%;
  height: ${props => (props.mobileFullHeight && '100%') || 'auto'};
  border-radius: ${pxToRem(8)};
  padding: ${pxToRem(16)} ${pxToRem(15)} ${pxToRem(30)};
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${media.desktop`
    padding: ${pxToRem(16)} ${pxToRem(22.5)} ${pxToRem(30)};
    width: ${(props: any) => props.width};
    height: auto;
    max-width: ${pxToRem(920)};
  `}
`
const StyledModalOverlay = styled.div<{ $closeOnOverlayClick: boolean }>`
  cursor: ${props => props.$closeOnOverlayClick ? 'pointer' : 'auto'};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: ${pxToRem(15)};
  z-index: 100;
  ${media.desktop`
    padding: 0;
    font-size: ${pxToRem(18)};
    padding-bottom: ${pxToRem(20)};
  `}
`

const CloseIcon = styled(FontAwesomeIcon)`
  color: #ff6820;
  font-size: ${pxToRem(20)};
`

const CloseButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: ${pxToRem(10)};
  position: absolute;
  top: ${pxToRem(14)};
  right: ${pxToRem(15)};
  margin: 0;
  color: #ff6820;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
  font-size: ${pxToRem(12)};
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  &:hover, &:focus, &:active {
    color: #ff6820;
  }
  ${media.desktop`
    font-size: ${pxToRem(18)};
    right: ${pxToRem(22.5)};
  `}
`

type ModalProps = {
  show: boolean
  onClose?: () => void
  children: React.ReactNode
  title?: string
  size?: 'large' | 'small' | 'medium'
  closeOnOverlayClick?: boolean
  backgroundColor?: string
  mobileFullHeight?: boolean
}

const Modal = ({
  show,
  onClose,
  children,
  title,
  size = 'large',
  backgroundColor,
  mobileFullHeight,
  closeOnOverlayClick = false,
}: ModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const handleCloseClick = (e: any) => {
    e.preventDefault()
    onClose && onClose()
  }

  const getWidth = () => {
    let width = '80%'
    switch (size) {
      case 'small':
        width = '40%'
        break
      case 'medium':
        width = '60%'
        break
      default:
        break
    }
    return width
  }

  const modalContent = show ? (
    <StyledModalOverlay $closeOnOverlayClick={closeOnOverlayClick} onClick={(event) => closeOnOverlayClick ? handleCloseClick(event) : void 0 }>
      <StyledModal width={getWidth()} backgroundColor={backgroundColor} mobileFullHeight={mobileFullHeight}>
        <StyledModalHeader>
          {title && <StyledModalTitle>{title}</StyledModalTitle>}
          <CloseButton onClick={handleCloseClick}>
            <CloseIcon icon="times" />
            Close
          </CloseButton>
        </StyledModalHeader>
        <StyledModalBody>{children}</StyledModalBody>
      </StyledModal>
    </StyledModalOverlay>
  ) : null

  if (isBrowser) {
    return <>{ ReactDOM.createPortal(
      modalContent,
      document.getElementById('modal-root')!
    ) } </>
  } else {
    return null
  }
}

export default Modal
