import { FC, useEffect } from 'react'
import GenericCard from '@ui/components/GenericCard'
import { MemberNewsDashboardProps, NewsInterface } from './types'
import { faNewspaper } from '@fortawesome/pro-regular-svg-icons/faNewspaper'
import { HeaderLinkInterface } from '@ui/components/GenericCard/types'
import styled from 'styled-components'
import pxToRem from '@app/ui/utils/pxToRem'
import Link from 'next/link'
import { media } from 'styled-bootstrap-grid'
import { useApp } from '@app/lib/hooks/app'
import MobileMemberNewsDashboard from './MobileMemberNewsDashboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton from 'react-loading-skeleton'
import { fetchNews, Status as NewsStatus, switchNewsBranch } from '@app/store/slices/news-slice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@app/store'
import { useUser } from '@app/lib/hooks/useUser'
import { slugToBranchPrefix } from '@app/ui/utils/slugToBranchPrefix'
import { ThunkDispatch } from '@reduxjs/toolkit'

const Container = styled.div`
  display: none;
  ${media.desktop`
    display: flex;
    flex-direction: column;
    background: #ECF3FA 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #B7D4E54D;
    border: 1px solid #C5D9E580;
    border-radius: 7px;
    padding-left: ${pxToRem(20)};
    padding-right: ${pxToRem(20)};
  `}
`

const CardHeaderWrapper = styled.div`
  padding-left: 0;
  padding-right: 0;
  padding-top: ${pxToRem(25)};
  padding-bottom: ${pxToRem(15.5)};
  border-bottom: 1px solid #b7d4e54d;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const CardHeaderTitle = styled.div`
  color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 900;
  font-size: ${pxToRem(18)};
  letter-spacing: 0px;
  line-height: 1.33333333;
`

const CardHeaderLink = styled.a`
  text-decoration: underline;
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  letter-spacing: 0px;
  color: #1a4469;
  &:hover {
    color: #ff6820;
  }
  display: flex;
  align-items: center;
`

const CardHeaderLinkIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.medium};
  color: ${props => props.theme.colors.warning};
`

const CardHeaderLinkText = styled.span`
  margin-left: ${pxToRem(8)};
`

const CardBodyWrapper = styled.div`
  padding-top: ${pxToRem(36.5)};
  padding-bottom: ${pxToRem(30)};
  display: flex;
  flex-direction: column;
  row-gap: ${pxToRem(26.5)};
`

const NewsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    padding-bottom: ${pxToRem(20.5)};
    border-bottom: 1px solid #b7d4e54d;
  }
`

const NewsItemDate = styled.div`
  font-size: ${pxToRem(12)};
  font-weight: 500;
  letter-spacing: -0.04px;
  color: #898989;
  line-height: 1;
`

const LinkWrapper = styled.div`
  margin-top: ${pxToRem(12)};
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

const NewsItemTitle = styled.a`
  font-size: ${pxToRem(18)};
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
  line-height: 1.11111111;
  ${LinkWrapper}:hover & {
    color: #00223C;
  }
`

const NewsItemLink = styled.a`
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  color: #898989;
  margin-left: 0;
  text-decoration: underline;
  line-height: 1.21428571;
  letter-spacing: 0px;
  ${LinkWrapper}:hover & {
    color: ${props => props.theme.colors.warning};
  }
  margin-top: ${pxToRem(15)};
`

const MemberNewsDashboard: FC<MemberNewsDashboardProps> = ({
  newsListingUrl
}) => {
  const { isDesktop } = useApp()
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const { session } = useUser()

  const {
    status,
    acfNews
  } = useAppSelector(state => state.news)

  useEffect(() => {
    if (status === NewsStatus.IDLE) {
      dispatch(fetchNews({ first: 10, after: null }))
    }
  }, [dispatch, status])

  // TODO: fix data flow so these updates are performed implicitly
  useEffect(() => {
    if (session?.isLoggedIn) dispatch(switchNewsBranch(fetchNews({ first: 10, after: null })))
  }, [dispatch, session?.data?.activeId, session?.isLoggedIn])

  const loadingSkeleton = <>
    <Skeleton
      count={1}
      width={'100%'}
      height={130}
    />
    <Skeleton
      count={1}
      width={'100%'}
      height={130}
    />
    <Skeleton
      count={1}
      width={'100%'}
      height={130}
    />
  </>

  const mobileLoadingSkeleton = <>
    <Skeleton
      count={1}
      width={'100%'}
      height={130}
    />
  </>


  let newsItems
  if (acfNews !== undefined) {
    const news = acfNews?.newsArticles?.edges
    newsItems = news?.length > 3 ? news.slice(0, 3) : news
  }

  const headerLink: HeaderLinkInterface | null = newsListingUrl
    ? {
      url: newsListingUrl,
      text: 'See all news',
      icon: faNewspaper,
      scroll: true,
    }
    : null

  if (isDesktop) {
    return (
      <Container>
        <CardHeaderWrapper>
          <CardHeaderTitle>Members news & updates</CardHeaderTitle>
          {newsListingUrl && (
            <Link href={newsListingUrl} passHref legacyBehavior>
              <CardHeaderLink>
                <CardHeaderLinkIcon icon={faNewspaper} />
                <CardHeaderLinkText>See all news</CardHeaderLinkText>
              </CardHeaderLink>
            </Link>
          )}
        </CardHeaderWrapper>
        {((status === NewsStatus.LOADING) && (
          loadingSkeleton
        )) || (
            <CardBodyWrapper>
              {newsItems
                ?.filter((newsItem: NewsInterface) => Boolean(newsItem.node))
                ?.map((newsItem: NewsInterface, index: number) => (
                  <NewsItemWrapper key={index}>
                    <NewsItemDate>{new Date(newsItem.node.date).toLocaleDateString('en-AU')?.replace(/\//gi, ' / ')}</NewsItemDate>
                    <LinkWrapper>
                      <Link href={slugToBranchPrefix(newsItem.node.uri, newsItem.node.__typename, session)} passHref legacyBehavior>
                        <NewsItemTitle>{newsItem.node.title}</NewsItemTitle>
                      </Link>
                      <Link href={slugToBranchPrefix(newsItem.node.uri, newsItem.node.__typename, session)} passHref legacyBehavior>
                        <NewsItemLink>Read article</NewsItemLink>
                      </Link>
                    </LinkWrapper>
                  </NewsItemWrapper>
                ))}
            </CardBodyWrapper>
          )}
      </Container>
    )
  }

  return (
    <GenericCard headerTitle="Members news & updates" headerLink={headerLink}>
      {((status === NewsStatus.LOADING) && (
        mobileLoadingSkeleton
      )) || (
          <MobileMemberNewsDashboard newsItems={newsItems} />
        )}
    </GenericCard>
  )
}

export default MemberNewsDashboard
