import { useAppSelector } from '@app/store'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { useLogout } from './useLogout'

import * as Sentry from '@sentry/nextjs'

export const useEjectTriggers = () => {
  const { dirty, wantLogout } = useAppSelector((state) => state?.app)
  const router = useRouter()
  const performLogout = useLogout()
  const handleLogout = useCallback(() => performLogout()
    .then(() => router.replace('/login'))
    .catch((error: any) => {
      Sentry.captureException(error)
    }), [router, performLogout])

  useEffect(() => {
    if (dirty) handleLogout()
  }, [dirty, handleLogout])

  useEffect(() => {
    if (wantLogout) handleLogout()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    dirty,
    wantLogout,
  }
}
