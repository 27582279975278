import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import GenericCard from '@ui/components/GenericCard'
import ArrowRightIcon from '../icons/ArrowRightIcon'
import Avatar, { ImgWrapper, BoxInformation, Name, Address, Img } from '../Avatar'
import ButtonWithIcon from '../ButtonWithIcon'
import { IssuesAtWorkProps } from './types'
import { FileAltIcon, CommentsAltIcon } from '@app/assets/icons'
import Image from 'next/image'
import { media } from 'styled-bootstrap-grid'
import pxToRem from '@app/ui/utils/pxToRem'
import { useApp } from '@app/lib/hooks/app'
import MobileCollapsableIssuesAtWork from './MobileCollapsableIssuesAtWork'
import { slugToBranchPrefix } from '@app/ui/utils/slugToBranchPrefix'
import { useUser } from '@app/lib/hooks/useUser'
import { useAppSelector } from '@app/store'
import avatarPlaceHolder from '@app/assets/images/avatar_placeholder.jpg'
import { getActiveBranchFromSession } from '@app/lib/utils'

export const Title = styled.div``

const CustomAvatar = styled(Avatar)`
  align-items: center;
  color: #012F57;
  margin-top: ${pxToRem(20)};
  ${Name} {
    font-size: ${pxToRem(18)};
    font-weight: 900;
  }
  ${Address} {
    font-size: ${pxToRem(12)};
    font-weight: 500;
  }
  ${BoxInformation} {
    line-height: ${pxToRem(22)};
  }
  ${media.desktop`
    margin-top: ${pxToRem(23)};
  `}
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${pxToRem(20)};
  height: ${pxToRem(18)};
  ${media.desktop`
    font-size: ${(props: any) => props.theme.fontSizes.large2x};
  `}
`

const TeamPageLink = styled.a`
  color: #1A4469;
  text-decoration: none;
  &:hover *,
  &:focus *,
  &:active * {
    color: #ff6820;
  }
`

const RepresentativeDetails: FC<{ representative: any }> = ({ representative }) => {
  return (!representative?.hidden && (representative?.name || representative?.teamPageUrl)) ? <CustomAvatar>
    {representative?.imageUrl &&
      (<ImgWrapper style={{ height: '55px' }}>
        <Img src={representative?.imageUrl} width={55} />
      </ImgWrapper>)}
    <BoxInformation>
      {representative?.name && <Name>{representative?.name}</Name>}
      {representative?.title && <Address>{representative?.title}</Address>}
    </BoxInformation>
  </CustomAvatar> : null
}

export const IssuesAtWork: FC<IssuesAtWorkProps & {
  mobileCollapsable?: boolean,
}> = ({
  awardLink,
  mobileCollapsable,
  branch,
}) => {
    const { isDesktop } = useApp()
    const {
      currentEmployerId,
      employers,
    } = useAppSelector(state => state.employers)
    const { session } = useUser()
    const [representative, setRepresentative] = useState<any>(null)

    const { branchSettings } = useAppSelector(state => state.app)
    const askQuestionLink = branchSettings?.issuesAtWorkAskAQuestion?.uri ?
      slugToBranchPrefix(branchSettings?.issuesAtWorkAskAQuestion?.uri, 'Page', session) : `/${getActiveBranchFromSession(session)?.toLowerCase()}/ask-a-question/`

    useEffect(() => {
      const currentEmployer = employers.find((employer: any) => employer.id === currentEmployerId)

      setRepresentative({
        name: currentEmployer?.representative,
        imageUrl: currentEmployer?.representativeImage ?? avatarPlaceHolder.src,
        title: currentEmployer?.representative && branchSettings?.representativeTitle,
        hidden: currentEmployer?.repIsHidden,
        teamPageUrl: currentEmployer?.teamPageUrl,
      })
    }, [branchSettings?.representativeTitle, currentEmployerId, employers])

    if (mobileCollapsable && !isDesktop) {
      return <MobileCollapsableIssuesAtWork askQuestionLink={askQuestionLink} awardLink={awardLink} representative={representative} />
    }

    return (
      <GenericCard headerTitle="Having issues at work?">
        <ButtonWithIcon
          path={askQuestionLink}
          title="Ask us a question"
          leftIcon={
            <Icon>
              <Image src={CommentsAltIcon} layout="fill" alt="" />
            </Icon>
          }
          rightIcon={<ArrowRightIcon />}
        />
        <ButtonWithIcon
          path={`/${branch ? branch.toLowerCase() : '#'}/your-award-or-agreement`}
          title="Your award or agreement"
          leftIcon={
            <Icon>
              <Image src={FileAltIcon} layout="fill" alt="" />
            </Icon>
          }
          rightIcon={<ArrowRightIcon />}
        />
        {representative?.teamPageUrl ?
          <TeamPageLink href={representative?.teamPageUrl}>
            <RepresentativeDetails representative={representative} />
          </TeamPageLink>
          :
          <RepresentativeDetails representative={representative} />}
      </GenericCard>
    )
  }

export default IssuesAtWork
