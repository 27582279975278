import { NoOverlaySpinner } from '@app/ui/components/Spinner'
import { useRouter } from 'next/router'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import styles from './styles.module.scss'

const Overlay = styled.div`
  background-color: #ECF3FA;
`

const SpinnerWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`

const WrappedSpinner: FC<{}> = () => <SpinnerWrapper><NoOverlaySpinner /></SpinnerWrapper>

export const TransitionLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const [transitionStage, setTransitionStage] = useState('fadeOut')
  const router = useRouter()

  const fadeOut = useCallback((url?: string) => {
    if(router.pathname === url) return
    document.body.style.overflow = 'hidden'
    setTransitionStage('fadeOut')
  }, [router.pathname])

  const fadeIn = useCallback((url?: string) => {
    if (router.pathname === url) return
    window?.scrollTo(0, 0)
    document.body.style.overflow = 'auto'
    setTransitionStage('fadeIn')
  }, [router.pathname])

  useEffect(() => {
    fadeIn()

    router.events.on('routeChangeStart', fadeOut)
    router.events.on('routeChangeComplete', fadeIn)
    router.events.on('routeChangeError', fadeIn)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Overlay>
      {transitionStage === 'fadeOut' && <WrappedSpinner/>}
      <div
        className={`${styles.content} ${styles[transitionStage]}`}>
        {children}
      </div>
    </Overlay>
  )
}
