import pxToRem from '@app/ui/utils/pxToRem'
import Link from 'next/link'
import { FC } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import SlickSlider from '@ui/components/SlickSlider'
import { NewsInterface } from './types'
import { slugToBranchPrefix } from '@app/ui/utils/slugToBranchPrefix'
import { useUser } from '@app/lib/hooks/useUser'

const NewsItemWrapper = styled.div`
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding-left: ${pxToRem(15)};
  padding-right: ${pxToRem(15)};
  height: ${pxToRem(119)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${pxToRem(20)};

  ${media.desktop`
    display: none;
    margin-bottom: 0;
  `}
`

const NewsItemDate = styled.div`
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: 500;
  color: #898989;
  letter-spacing: -0.03px;
  line-height: 1;
`

const NewsItemTitle = styled.a`
  font-size: ${pxToRem(15)};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  line-height: 1.33333333;
  letter-spacing: -0.04px;
  margin-top: ${pxToRem(7)};
  &:hover {
    color: #00223C;
  }
`

const NewsItemLink = styled.a`
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  color: #898989;
  margin-left: 0;
  text-decoration: underline;
  ${NewsItemWrapper}:hover & {
    color: ${props => props.theme.colors.warning};
  }
  letter-spacing: 0px;
  line-height: 1.21428571;
  margin-top: ${pxToRem(10)};
`

const SliderContainer = styled.div`
  & > div > ul {
    bottom: ${pxToRem(-10)} !important;

    & li:not(.slick-active) button {
      opacity: 0.25;
    }

    & li button {
      height: ${pxToRem(10)};
      width: ${pxToRem(10)};
      padding: 0;
      background: #FF6820;
      border-radius: 50%;

      &:before {
        display: none;
      }
    }
  }
`

interface MobileMemberNewsDashboardProps {
  newsItems: NewsInterface[]
}

const MobileMemberNewsDashboard: FC<MobileMemberNewsDashboardProps> = ({ newsItems }) => {
  const { session } = useUser()

  return (
    <SliderContainer>
      <SlickSlider>
      {newsItems
        ?.filter((newsItem: NewsInterface) => Boolean(newsItem.node))
        ?.map((newsItem, index) => (
          <div key={index}>
            <NewsItemWrapper>
              <NewsItemDate>{new Date(newsItem.node.date).toLocaleDateString('en-AU')?.replace(/\//gi, ' / ')}</NewsItemDate>
              <Link href={slugToBranchPrefix(newsItem.node.uri, newsItem.node.__typename, session)} passHref legacyBehavior>
                <NewsItemTitle>{newsItem.node.title}</NewsItemTitle>
              </Link>
              <Link href={slugToBranchPrefix(newsItem.node.uri, newsItem.node.__typename, session)} passHref legacyBehavior>
                <NewsItemLink>Read article</NewsItemLink>
              </Link>
            </NewsItemWrapper>
          </div>
        ))}
      </SlickSlider>
    </SliderContainer>
  )
}

export default MobileMemberNewsDashboard
