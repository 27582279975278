//-----------------
// This component is the 'Award/EBA FAQ' widget that displays on the dashboard and the 'Your award or agreement' page.
// It has a prop downloadAlternatePosition which causes the layout of the component to be slightly different on the 'Your award or agreement' page.
//-----------------

import { faFileAlt, faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons'
import React, { FC, useState } from 'react'
import GenericCard from '@ui/components/GenericCard'
import { HeaderLinkInterface } from '@ui/components/GenericCard/types'
import { AwardAccordionProps } from './types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { media } from 'styled-bootstrap-grid'
import pxToRem from '@app/ui/utils/pxToRem'
import { GridItem } from '@ui/components/PayRateIndicator'
import { useApp } from '@app/lib/hooks/app'
import { parseHTML } from '@app/lib/parseHTML'
import InlineDownload from '@app/ui/components/InlineDownload'
import { useUser } from '@app/lib/hooks/useUser'
import { cleanDoubleSlashes, getActiveBranchFromSession } from '@app/lib/utils'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${pxToRem(15)};
  position: relative;
  ${media.desktop`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${pxToRem(20)};
    overflow: hidden;
  `}
`

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToRem(20)};
  position: relative;
  ${media.desktop`
    display: grid;
    grid-template-columns: 1fr;
  `}
`

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrapper = styled.div<{
  isActive?: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F3F3F3;
  border-radius: 7px;
  padding-top: ${pxToRem(11)};
  padding-bottom: ${pxToRem(11)};
  padding-left: ${pxToRem(16)};
  padding-right: ${pxToRem(16)};
  cursor: pointer;
  font-size: ${pxToRem(15)};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  &:hover {
    background-color: #ECF3FA;
  }
  ${props => props.isActive && `
    background-color: #ECF3FA;
    svg {
      color: #F7570B;
    }
  `}
  ${media.desktop`
    padding: ${pxToRem(15)} ${pxToRem(19)} ${pxToRem(16)};
  `}
`

const TitleIcon = styled(FontAwesomeIcon)`
  ${TitleWrapper}:hover & {
    color: #F7570B;
  }
`

const ContentWrapper = styled.div<{
  isActive?: boolean
}>`
  background-color: #ECF3FA;
  border-radius: ${pxToRem(7)};
  transition: all 0.3s ease;
  font-size: ${pxToRem(15)};
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  p:not(:last-child) {
    margin-top: 0;
    margin-bottom: 0;
  }
  ul {
    margin: 0;
    li {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin-left: 30px;
      position: relative;

      &:before {
        position: absolute;
        max-width: 15px;
        overflow: hidden;
        left: -25px;
        top: -2px;
        direction: rtl;
        font-weight: bold;
        font-size: 20px;
        content: '\\27F6';
        color: #012F57;
      }
    }
  }
  ${props => props.isActive && `
    margin-top: ${pxToRem(15)};
    padding: ${pxToRem(16)};
    visibility: visible;
    opacity: 1;
    max-height: 3000px;
  `}
  ${media.desktop`
    font-size: ${pxToRem(16)};
    ${(props: any) => props.isActive && `
      padding: 0;
      margin-bottom: ${pxToRem(20)};
      margin-top: ${pxToRem(23)};
    `}
  `}
`

const ContentTitle = styled.label`
  font-weight: 700;
  font-size: ${pxToRem(18)};
  color: #012F57;
  margin-bottom: ${pxToRem(12)};
  padding: ${pxToRem(20)} ${pxToRem(19)} 0 ${pxToRem(19)};
  display: block;
`

const SubGrid = styled.div`
  display: grid;
  grid-column-gap: ${pxToRem(20)};
  position: relative;
  grid-template-columns: 1fr 1fr;
`

const AccordionWYSIWYG = styled.div`
  padding: 0 ${pxToRem(19)} ${pxToRem(23)} ${pxToRem(19)};
  p:not(:last-child) {
    margin-bottom: ${pxToRem(18)};
  }
`

const AwardAccordion: FC<AwardAccordionProps> = ({ awardSummaryLink, accordions, downloadAlternatePosition }) => {
  const [active, setActive] = useState<number | null>(null)
  const { isDesktop } = useApp()
  const branch = getActiveBranchFromSession(useUser().session)?.toLowerCase()
  const showDownloadAtBottom = (awardSummaryLink && downloadAlternatePosition)

  if(Array.isArray(accordions)) {
    accordions = accordions?.filter(item => (item?.bodyHtml.replace(/<p>&nbsp;<\/p>/g, '')) && (item?.bodyHtml.replace(/<p>&nbsp;<\/p>/g, '')?.trim().length > 0))
  }
  else {
    accordions = []
  }

  if(accordions.length === 0 &&! showDownloadAtBottom) {
    return null
  }

  const headerLink: HeaderLinkInterface | null = awardSummaryLink
    ? {
      url: `/${branch ? branch : '#'}/your-award-or-agreement`,
      text: 'View your award or EBA',
      icon: faFileAlt,
      hideOnMobile: true,
    }
    : null
  const isEvenNumber = (value: number) => (value % 2 === 0)

  const toggleAccordion = (index: number) => {
    if (index === active) {
      setActive(null)
    } else {
      setActive(index)
    }
  }

  const renderDesktopAccordion = () => {
    return accordions!.reduce((gridItems: React.ReactElement[], accordion, index) => {
      const nextAccordionIndex = index + 1

      const nextAccordion = accordions![nextAccordionIndex]
      if (isEvenNumber(index)) {
        gridItems.push(
          <SubGrid key={index}>
            <GridItem as={AccordionWrapper} onClick={() => toggleAccordion(index)}>
              <TitleWrapper isActive={active === index}>
                {accordion.title}
                <TitleIcon icon={(active === index) ? faMinus : faPlus} />
              </TitleWrapper>
            </GridItem>
            {
              nextAccordion &&
              <GridItem as={AccordionWrapper} onClick={() => toggleAccordion(nextAccordionIndex)}>
                <TitleWrapper isActive={nextAccordionIndex === active}>
                  {nextAccordion.title}
                  <TitleIcon icon={(nextAccordionIndex === active) ? faMinus : faPlus} />
                </TitleWrapper>
              </GridItem>
            }
            <GridItem as={ContentWrapper} span={2} isActive={active === index}>
              <ContentTitle>{accordion.title}</ContentTitle>
              <AccordionWYSIWYG>{parseHTML(accordion.bodyHtml.replace(/<p>&nbsp;<\/p>/g, ''))}</AccordionWYSIWYG>
            </GridItem>
            {
              nextAccordion &&
              <GridItem as={ContentWrapper} span={2} isActive={nextAccordionIndex === active}>
                <ContentTitle>{nextAccordion.title}</ContentTitle>
                <AccordionWYSIWYG>{parseHTML(nextAccordion.bodyHtml.replace(/<p>&nbsp;<\/p>/g, ''))}</AccordionWYSIWYG>
              </GridItem>
            }
          </SubGrid>
        )
      }
      return gridItems
    }, []).map((gridItem) => (gridItem))
  }

  const renderMobileAccordion = () => {
    return accordions!.filter((_item, index) => (index < 6)).map((accordion, index) => (
      <AccordionWrapper key={index}>
        <TitleWrapper isActive={active === index} onClick={() => toggleAccordion(index)}>
          {accordion.title}
          <TitleIcon icon={active === index ? faMinus : faPlus} />
        </TitleWrapper>
        <ContentWrapper isActive={active === index}>
          <ContentTitle>{accordion.title}</ContentTitle>
          <AccordionWYSIWYG>{parseHTML(accordion.bodyHtml.replace(/<p>&nbsp;<\/p>/g, ''))}</AccordionWYSIWYG>
        </ContentWrapper>
      </AccordionWrapper>
    ))
  }

  return (
    <GenericCard faqCard={true} headerTitle="What am I entitled to under my award or EBA?" headerLink={!(showDownloadAtBottom) ? headerLink : undefined}>
      <BodyWrapper>
        {isDesktop ? renderDesktopAccordion() : renderMobileAccordion()}
      </BodyWrapper>
      {showDownloadAtBottom && (
        <DownloadWrapper>
          <InlineDownload
            downloadTitle='Download your EBA summary'
            downloadUrl={cleanDoubleSlashes(awardSummaryLink) ?? '#'}
          />
        </DownloadWrapper>
      )}
    </GenericCard>
  )
}

export default AwardAccordion
