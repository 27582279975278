import pxToRem from '@app/ui/utils/pxToRem'
import { useDispatch } from 'react-redux'
import PrimaryGenericCard, { FlexBox } from '@ui/components/PrimaryGenericCard'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton from 'react-loading-skeleton'
import { useAppSelector } from '@app/store'
import {
  IWorkplaceInfoIndicator as WorkplaceInfoIndicatorProps,
  Status,
  pushClearEmploymentData,
  clearEmploymentLevel,
} from '@app/store/slices/employer-slice'
import { capitalizeFirstLetter, getActiveBranchFromSession } from '@app/lib/utils'
import { FC } from 'react'
import { useUser } from '@app/lib/hooks/useUser'
import { ThunkDispatch } from 'redux-thunk'

const BadgeInfo = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: ${pxToRem(8)};
  align-items: center;
  padding: ${pxToRem(6)} ${pxToRem(15)};
  background-color: #E0EDFA;
  border-radius: ${pxToRem(10)};
  font-size: ${(props: any) => props.theme.fontSizes.small2x};
  color: ${(props: any) => props.theme.colors.primary};
  font-weight: 500;
  flex-wrap: initial;
  line-height: 1.3;
  svg {
    font-size: ${(props: any) => props.theme.fontSizes.small2x};
    color: ${(props: any) => props.theme.colors.info};
    width: ${pxToRem(20)} !important;
  }
`
const UpdateDetailLink = styled.a`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${pxToRem(5)};
  font-size: ${(props: any) => props.theme.fontSizes.small2x};
  color: #1A4469;
  text-decoration: underline;
  &:hover, &:focus, &:active {
    color: #ff6820;
  }
  svg {
    font-size: ${(props: any) => props.theme.fontSizes.medium};
    color: #ff6820;
  }
`

const CardFooter = styled.div`
  margin-top: ${pxToRem(20)};
`

const CardBody = styled.div`
  gap: ${pxToRem(10)};
  display: flex;
  flex-wrap: wrap;
`

const WorkplaceInfoIndicator: FC<WorkplaceInfoIndicatorProps> = ({
  title,
  employer,
  location,
  employmentStatus,
  level,
}) => {
  const {
    status,
  } = useAppSelector(state => state.employers)
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const { branchSettings } = useAppSelector(state => state.app)

  const scrollToTop = () => {window.scrollTo(0, 0)}

  const { session } = useUser()

  return (
    <PrimaryGenericCard headerTitle={title} backgroundColor="#F3F8FC">
      <FlexBox >
        {
          (status === Status.LOADING || !employer) ? (
            <div>
              <Skeleton
                count={1}
                width={'100%'}
                height={30}
              />
              <Skeleton
                count={1}
                width={'100%'}
                height={30}
              />
              <Skeleton
                count={1}
                width={'100%'}
                height={30}
              />
              <Skeleton
                count={1}
                width={'100%'}
                height={30}
              />
            </div>
          ) : (
            <>
              <CardBody>
                <BadgeInfo>
                  <FontAwesomeIcon
                    icon={{
                      iconName: 'store',
                      prefix: 'far'
                    }}
                  />
                  {employer}
                </BadgeInfo>
                {location && <BadgeInfo>
                  <FontAwesomeIcon
                    icon={{
                      iconName: 'map-marker-alt',
                      prefix: 'far'
                    }}
                  />
                  {location}
                </BadgeInfo>}
                {
                  employmentStatus &&
                  <BadgeInfo>
                    <FontAwesomeIcon
                      icon={{
                        iconName: 'suitcase',
                        prefix: 'far'
                      }}
                    />
                    {capitalizeFirstLetter(employmentStatus)}
                  </BadgeInfo>
                }
                {
                  (level && employmentStatus !== 'salary') && <BadgeInfo>
                    <FontAwesomeIcon
                      icon={{
                        iconName: 'suitcase',
                        prefix: 'far'
                      }}
                    />
                    {level}
                  </BadgeInfo>
                }
              </CardBody>

              <CardFooter>
                <UpdateDetailLink
                  href={branchSettings?.workplaceCtaUrl ?? `/${getActiveBranchFromSession(session)?.toLowerCase()}/update-your-details/`}
                  target={branchSettings?.workplaceCtaTarget ?? '_self'}
                >
                  <FontAwesomeIcon
                    icon={{
                      iconName: 'cog',
                      prefix: 'far'
                    }}
                  />{branchSettings?.workplaceCtaText ?? 'Update your workplace details'}
                </UpdateDetailLink>
                <br />
                {( (employmentStatus?.toLowerCase() === 'salary') || (employmentStatus && level)) &&
                  <UpdateDetailLink
                    href="#"
                    onClick={async (event) => {
                      event.preventDefault()
                      dispatch(clearEmploymentLevel())
                      dispatch(pushClearEmploymentData())
                      scrollToTop()
                    }}
                  >
                    <FontAwesomeIcon
                      icon={{
                        iconName: 'cog',
                        prefix: 'far'
                      }}
                    /> Reset your pay rate details
                  </UpdateDetailLink>
                }
              </CardFooter>
            </>
          )
        }
      </FlexBox>
    </PrimaryGenericCard>
  )
}

export default WorkplaceInfoIndicator
