import pxToRem from '@app/ui/utils/pxToRem'
import { faCommentAlt, faFileAlt, faLongArrowAltRight, faMinus, faPlus, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { FC, useState } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { IssuesAtWorkProps } from './types'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToRem(10)};

  ${media.desktop`
    display: none;
    margin-top: 0;
  `}
`

const TitleWrapper = styled.div<{ open: boolean }>`
  background: #ECF3FA 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${pxToRem(11)};
  padding-bottom: ${pxToRem(11)};
  padding-left: ${pxToRem(10)};
  padding-right: ${pxToRem(10)};
  cursor: pointer;
  ${props => props.open ? `
    background-color: #ffffff;

    & > svg > path {
      fill: #FF6820;
    }
  ` : `
    background-color: #ECF3FA;

    & > svg > path {
      fill: #00AFD9;
    }
  `}
`

const Title = styled.div`
  font-size: ${pxToRem(15)};
  font-weight: 900;
  color: ${props => props.theme.colors.primary};
`

const TitleIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.normal};
`

const BodyWrapper = styled.div<{
  open: boolean
}>`
  background: #ECF3FA 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #E8E8E866;
  border: 1px solid #E2ECF2;
  border-radius: 7px;
  transition: all 0.15s ease;
  ${props => props.open ? `
    margin-top: ${pxToRem(15)};
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
    padding-top: ${pxToRem(19)};
    padding-bottom: ${pxToRem(19)};
    padding-left: ${pxToRem(13)};
    padding-right: ${pxToRem(13)};
  ` : `
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  `}
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${pxToRem(15)};
`

const LinkWrapper = styled.div`
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${pxToRem(11)};
  padding-bottom: ${pxToRem(11)};
  padding-left: ${pxToRem(15)};
  padding-right: ${pxToRem(15)};
  cursor: pointer;
  &:hover {
    background-color: #F3F3F3;
  }
`

const IssueText = styled.div`
  font-size: ${pxToRem(15)};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
`

const IssueIconWrapper = styled.div`
  margin-right: ${pxToRem(6)};
  width: ${pxToRem(20)};
  display: flex;
  align-items: center;
`

const IssueIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.medium};
  color: ${props => props.theme.colors.info};
  ${LinkWrapper}:hover & {
    color: ${props => props.theme.colors.warning};
  }
`

const LinkIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.normal};
  color: ${props => props.theme.colors.primary};
  ${LinkWrapper}:hover & {
    color: ${props => props.theme.colors.warning};
  }
`

const RepresentativeWrapper = styled.div`
  margin-top: ${pxToRem(20)};
  display: flex;
`

const Image = styled.img`
  width: ${pxToRem(51)};
  height: ${pxToRem(51)};
  border: 2px solid #FFFFFF;
  border-radius: 9999px;
`

const RepresentativeBodyWrapper = styled.div`
  margin-left: ${pxToRem(10)};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const RepresentativeName = styled.div`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: 900;
  color: ${props => props.theme.colors.primary};
`

const RepresentativeTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.smallx};
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
`

interface IssueLinkProps {
  icon: IconDefinition
  text: string
  link: string
}

const IssueLink: FC<IssueLinkProps> = ({ icon, text, link }) => {
  return link ? (
    <Link href={link} passHref legacyBehavior>
      <LinkWrapper>
        <IssueText>
          <IssueIconWrapper><IssueIcon icon={icon} /></IssueIconWrapper>
          {text}
        </IssueText>
        <LinkIcon icon={faLongArrowAltRight} />
      </LinkWrapper>
    </Link>
  ) : null
}

const MobileCollapsableIssuesAtWork: FC<IssuesAtWorkProps> = ({
  askQuestionLink,
  awardLink,
  representative,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <TitleWrapper open={open} onClick={() => setOpen(!open)}>
        <Title>Having issues at work?</Title>
        <TitleIcon icon={open ? faMinus : faPlus} />
      </TitleWrapper>
      <BodyWrapper open={open}>
        <LinksWrapper>
          <IssueLink icon={faCommentAlt} text="Ask us a question" link={askQuestionLink as string} />
          {awardLink && <IssueLink icon={faFileAlt} text="Your award or agreement" link={awardLink} />}
        </LinksWrapper>
        {(!representative?.hidden && (representative?.name || representative?.title)) && (
          <RepresentativeWrapper>
            {representative?.imageUrl && <Image src={representative.imageUrl} alt="" />}
            <RepresentativeBodyWrapper>
              {representative?.name && <RepresentativeName>{representative.name}</RepresentativeName>}
              {representative?.title && <RepresentativeTitle>{representative.title}</RepresentativeTitle>}
            </RepresentativeBodyWrapper>
          </RepresentativeWrapper>
        )}
      </BodyWrapper>
    </Container>
  )
}

export default MobileCollapsableIssuesAtWork
