import { FC, PropsWithChildren, useEffect, useRef, forwardRef, Ref } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { ThunkDispatch } from '@reduxjs/toolkit'
import UpcomingEvents from '@ui/components/UpcomingEvents'
import LatestDiscounts from '@ui/components/LatestDiscounts'
import Masonry, { MasonryPropTypes } from 'react-masonry-component'
import FeaturedCampaign from '@ui/components/FeaturedCampaign'
import MemberNewsDashboard from '@ui/components/MemberNewsDashboard'
import { fetchDiscounts, Status, switchDiscountsBranch } from '@app/store/slices/discounts-slice'
import { fetchEvents, switchEventsBranch } from '@app/store/slices/events-slice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@app/store'
import { secondaryDashboardCampaign } from './types'
import { slugToBranchPrefix } from '@app/ui/utils/slugToBranchPrefix'
import { useUser } from '@app/lib/hooks/useUser'
import { formatDate } from '@app/lib/utils'
import pxToRem from '@app/ui/utils/pxToRem'

const Container = styled.div`
  width: 100%;
  ${media.tablet`
    padding: 0 ${pxToRem(12)};
  `}
`

const GridItemWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  ${media.tablet`
    margin-bottom: ${pxToRem(28)};
    width: calc(50% - 14px);
  `}
  &:empty {
    margin-bottom: 0;
  }
`

/* FIXME: this is a hack to get around the fact that react-masonry-component has limited support for typescript */
type WrappedMasonryProps = PropsWithChildren<MasonryPropTypes> & { ref?: Ref<any> }

/* eslint-disable-next-line react/display-name */
const WrappedMasonry = forwardRef<any, WrappedMasonryProps>((props, ref) => (<Masonry {...props} ref={ref} />))

const DashboardGrid: FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const { session } = useUser()
  const {
    status: discountsStatus,
    acfDiscounts
  } = useAppSelector(state => state.discounts)

  const {
    acfEvents,
    status: eventsStatus,
  } = useAppSelector(state => state.events)
  const masonryRef = useRef<any>(null)

  const { branchSettings } = useAppSelector(state => state.app)


  let discounts: any = []
  for (let i = 0; acfDiscounts?.benefits?.edges && i < acfDiscounts?.benefits?.edges?.length && i < 2; i++) {
    const d = acfDiscounts?.benefits?.edges[i]

    discounts.push(
      {
        discountTitle: d?.node?.benefitsFields?.displayTitle ? d?.node?.benefitsFields?.displayTitle : d?.node?.title,
        discountLink: d?.node?.uri,
        discountImage: d?.node?.benefitsFields?.featuredImage?.mediaItemUrl,
        discountLinkText: d?.node?.benefitsFields?.linkText,
        discountIsExternal: d?.node?.benefitsFields?.externalLink
      }
    )
  }

  let events: any = []
  const retrievedEvents = acfEvents?.events?.edges
  for (let i = 0; retrievedEvents && i < retrievedEvents.length && i < 2; i++) {
    const e = retrievedEvents[i]?.node

    let niceEventDate = ''
    let niceEventEndDate = ''
    if (e?.eventsFields?.isCancelled) {
      niceEventDate = 'CANCELLED'
    }
    else {
      niceEventDate = e?.eventsFields?.eventStartDate
      niceEventEndDate = e?.eventsFields?.eventEndDate

      if (niceEventDate && niceEventDate.length !== 0) {
        niceEventDate = formatDate(niceEventDate, ' / ')
      }

      if (niceEventEndDate && niceEventEndDate.length !== 0) {
        niceEventEndDate = formatDate(niceEventEndDate, ' / ')
      }
    }

    events.push(
      {
        eventImage: e?.eventsFields?.image?.mediaItemUrl,
        isFree: e?.eventsFields?.isFreeEvent,
        isMovie: e?.eventsFields?.isAMovie,
        isCancelled: e?.eventsFields?.isCancelled,
        eventStartDate: niceEventDate,
        eventEndDate: niceEventEndDate,
        eventType: e?.eventsFields?.eventType,
        eventLink: e?.uri,
        eventTitle: e?.eventsFields?.displayTitle ?? e?.title,
      }
    )
  }

  useEffect(() => {
    if (discountsStatus === Status.IDLE) {
      dispatch(fetchDiscounts({ first: 10, after: null }))
    }
  }, [discountsStatus, dispatch])

  useEffect(() => {
    if (eventsStatus === Status.IDLE) {
      dispatch(fetchEvents({ first: 10, after: null }))
    }
  }, [eventsStatus, dispatch])

  // TODO: fix data flow so these updates are performed implicitly
  useEffect(() => {
    if (session?.isLoggedIn) {
      dispatch(switchDiscountsBranch(fetchDiscounts({ first: 10, after: null })))
      dispatch(switchEventsBranch(fetchEvents({ first: 10, after: '' })))
    }
  }, [dispatch, session?.data?.activeId, session?.isLoggedIn])

  useEffect(() => {
    // FIXME: for Safari
    require('requestidlecallback-polyfill')

    const resizeHandler = () => {
      requestIdleCallback(() => masonryRef?.current.masonry.layout())
    }
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  const firstSecondaryCampaign = branchSettings?.secondaryWidget?.length > 0 ? branchSettings?.secondaryWidget[0] : null

  return (
    <Container>
      <WrappedMasonry
        options={{
          initLayout: true,
          gutter: 28,
          fitWidth: false,
        }}
        enableResizableChildren
        style={{
          position: 'relative',
          width: '100%'
        }}
        ref={masonryRef}
      >
        <GridItemWrapper>
          <UpcomingEvents
            eventsListingLink={slugToBranchPrefix('/events', 'event', session)}
            events={events}
          />
        </GridItemWrapper>
        <GridItemWrapper>
          <MemberNewsDashboard
            newsListingUrl={slugToBranchPrefix('/news', 'newsArticle', session)}
          />
        </GridItemWrapper>
        <GridItemWrapper>
          <LatestDiscounts
            listingUrl={slugToBranchPrefix('/benefits', 'benefit', session)}
            discounts={discounts}
          />
        </GridItemWrapper>
        {firstSecondaryCampaign?.ctaLink ?
          <GridItemWrapper>
            <FeaturedCampaign
              campaignImage={firstSecondaryCampaign?.featuredImage?.mediaItemUrl}
              campaignTitle={firstSecondaryCampaign?.title}
              campaignSummary={firstSecondaryCampaign?.description}
              campaignCTAText={firstSecondaryCampaign?.ctaLink?.title}
              campaignLink={slugToBranchPrefix(firstSecondaryCampaign?.ctaLink?.url, firstSecondaryCampaign?.relatedPost?.__typename, session)}
              campaignTarget={firstSecondaryCampaign?.ctaLink?.target}
            />
          </GridItemWrapper>
          : null}
        {branchSettings?.secondaryWidget?.map((secondaryDashboardCampaign: secondaryDashboardCampaign, i: number) => (
          i > 0 && secondaryDashboardCampaign?.ctaLink ?
            <GridItemWrapper key={i}>
              <FeaturedCampaign
                campaignImage={secondaryDashboardCampaign?.featuredImage?.mediaItemUrl}
                campaignTitle={secondaryDashboardCampaign?.title}
                campaignSummary={secondaryDashboardCampaign?.description}
                campaignCTAText={secondaryDashboardCampaign?.ctaLink?.title}
                campaignLink={slugToBranchPrefix(secondaryDashboardCampaign?.ctaLink?.url, secondaryDashboardCampaign?.relatedPost?.__typename, session)}
                campaignTarget={secondaryDashboardCampaign?.ctaLink?.target}
              />
            </GridItemWrapper>
            : null
        ))}
      </WrappedMasonry>
    </Container>
  )
}

export default DashboardGrid
