export const branchPrefixes = [
  'national',
  'sa',
  'wa',
  'tas',
  'qld',
  'nsw',
  'newcastle',
  'vic'
]
