import { excludeProps } from '@app/ui/utils/shouldForwardProp'
import styled from 'styled-components'
export type AvatarProps = {
  variant? : 'default' | 'primary',
  display? : string,
  flexDirection? : string,
  alignItems? : string,
  justifyContent? : string,
  marginTop? : number,
}
export const Avatar = styled
  .div
  .withConfig({
    shouldForwardProp: excludeProps(['variant'])
  })
 <AvatarProps>`
  display : ${p => `${p.display || 'flex'}`};
  flex-direction : ${p => `${p.flexDirection || 'row'}`};
  align-items : ${p => `${p.alignItems || 'flex-start'}`};
  justify-content : ${p => `${p.justifyContent || 'center'}`};
  margin-top : ${p => `${p.marginTop || 0}rem`};`
export const BoxInformation = styled(Avatar)`
  flex: 1;
  flex-direction : column;
  width : 100%;
  align-items : flex-start;
  justify-content : center;
  padding-left : 0.625rem;
`
export const Name = styled.div`
  font-weight : bold;
  font-size : 1.2rem
`
export const Address = styled.div`
  font-size : 1rem
`
export const ImgWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Img = styled.img`
  height: 100%;
`
export default Avatar
