import Footer from '@app/ui/components/Footer'
import MenuHeader from '@app/ui/components/MenuHeader'
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import PrimaryNavMenu from '@app/ui/components/PrimaryNavMenu'
import MobileSubMenu from '@app/ui/components/MobileSubMenu'
import { useAppSelector } from '@app/store'
import { useFooter } from '@app/lib/hooks/useFooter'
import { useDispatch } from 'react-redux'
import { closeMobileNavMenu, fetchBranchSettings, fetchMenu, fetchMobileMenu, setMenuLUT } from '@app/store/slices/app-slice'
import { useRouter } from 'next/router'
import { createMenuLUT } from '@app/lib/parseMenuEntry'
import { Status } from '@app/store/slices/app-slice'
import { propagateUserData } from '@app/store/slices/auth-slice'
import { useUser } from '@app/lib/hooks/useUser'
import { ThunkDispatch } from 'redux-thunk'

const Container = styled.div<{
  $mobileNavMenuIsOpened: boolean,
}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background-color: #F3F8FC;
  ${props => props.$mobileNavMenuIsOpened && `
    width: 100%;
  `}
`

const StyledMain = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: #F3F8FC;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const SiteContainer: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const router = useRouter()
  const footerProps = useFooter()
  const { session, isValidating } = useUser()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [wantNewMenu, setWantNewMenu] = useState<boolean>(true)

  const {
    primaryMenu,
    mobileMenu,
    mobileNavMenuIsOpened,
    branchSettings,
  } = useAppSelector(state => state.app)
  const { status: authLoadingStatus } = useAppSelector(state => state.auth)
  const { employers, currentEmployerId } = useAppSelector(state => state.employers)

  const setUserFromLocation = useCallback((employerId: string) => {
    const employerBranch = employers.find((employer: any) => employer.id === employerId)?.branch
    const allUserLocations = Object.values(session?.data?.['https://sda.com.au/locations'] ?? []).flat()
    const branchUser = (allUserLocations.find((location: any) => location.branch === employerBranch) as any)?.id

    dispatch(propagateUserData({
      activeBranch: employerBranch,
      userId: branchUser,
      employerId,
    }))
  }, [dispatch, employers, session?.data])

  const fetchGlobalData = useCallback(() => {
    // FIXME: for Safari
    require('requestidlecallback-polyfill')
    const { query } = router
    const preview = query.preview === 'true'
    const previewToken = query.token

    requestIdleCallback(() => {
      dispatch(closeMobileNavMenu())
      dispatch(fetchBranchSettings({
        preview,
        previewToken,
      }))
      dispatch(fetchMenu({
        preview,
        previewToken,
      }))
      dispatch(fetchMobileMenu({
        preview,
        previewToken,
      }))
    })
  }, [dispatch, router])

  useEffect(() => {
    const menuLUT = createMenuLUT(primaryMenu)

    dispatch(setMenuLUT(menuLUT))
  }, [dispatch, primaryMenu])

  // If the employer id has changed, and the session is not loading
  // save the new employer id in session
  useEffect(() => {
    if (!isValidating && currentEmployerId !== session?.data?.employerId) {
      dispatch({ type: 'RESET '})
      void setUserFromLocation(currentEmployerId)
    }
  }, [dispatch, currentEmployerId, isValidating, session?.data?.employerId, setUserFromLocation])

  // If the menu is not loading, the session is not loading/being changed,
  // and the session employer id does not match the redux employer id
  // flag that a new menu is wanted
  useEffect(() => {
    if (
      authLoadingStatus !== Status.LOADING &&
      currentEmployerId !== session?.data?.employerId
    ) {
      setWantNewMenu(true)
    }
  }, [
    dispatch,
    currentEmployerId,
    session?.data?.employerId,
    authLoadingStatus,
  ])

  // If a new menu is wanted, and now the redux employer id matches the session employer id
  // flag the menu as loading
  // load the menu
  // flag that a new menu is not wanted anymore
  useEffect(() => {
    if (wantNewMenu && (!primaryMenu || primaryMenu.length === 0 || currentEmployerId === session?.data?.employerId)) {
      setIsLoading(true)
      fetchGlobalData()
      setWantNewMenu(false)
    }
  }, [currentEmployerId, session?.data?.employerId, fetchGlobalData, wantNewMenu, primaryMenu])

  // If a new menu has been loaded
  // lower the loading flag
  useEffect(() => {
    if (Array.isArray(primaryMenu) && primaryMenu.length > 0) setIsLoading(false)
  }, [primaryMenu])

  return (
    <Container $mobileNavMenuIsOpened={mobileNavMenuIsOpened}>
      <MenuHeader menuItems={[]} />
      <StyledMain>
        <PrimaryNavMenu
          menuItems={primaryMenu}
          ctaItems={branchSettings?.sidebarCTA}
          loading={isLoading}
        />
        <Wrapper>
          {children}
        </Wrapper>
      </StyledMain>
      <Footer {...footerProps} />
      {!mobileNavMenuIsOpened && (
        <MobileSubMenu primaryLinks={mobileMenu} />
      )}
    </Container>
  )
}

export default SiteContainer
