import { SyntheticEvent } from 'react'

export const curryMenuLinkHandler =
  (
    index: number | null,
    menuItem: any,
    callback: any,
    isExternalFlag: boolean,
    absoluteIndex: number | null,
    isTopLevelEntry = false,
  ) => isExternalFlag && !isTopLevelEntry ?
      // Curry two different handlers depending on whether the links is internal, or external
      (event: SyntheticEvent) => {
        event.preventDefault()
        window.open(menuItem.itemUrl ? String(menuItem.itemUrl) : 'about:blank')
      } :
      (event: SyntheticEvent) => {
        event.preventDefault()
        callback(menuItem, index, absoluteIndex, isTopLevelEntry)
      }
