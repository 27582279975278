import { useApp } from '@app/lib/hooks/app'
import pxToRem from '@app/ui/utils/pxToRem'
import { faExternalLinkSquareAlt, faTag } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { FC } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import GenericCard from '@ui/components/GenericCard'
import { HeaderLinkInterface } from '@ui/components/GenericCard/types'
import MobileLatestDiscounts from './MobileLatestDiscounts'
import { LatestDiscountsProps } from './types'
import { useUser } from '@app/lib/hooks/useUser'
import { slugToBranchPrefix } from '@app/ui/utils/slugToBranchPrefix'

const Wrapper = styled.div`
  display: none;
  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: ${pxToRem(15)};
  `}
`

const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${pxToRem(214)};
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
    background-color: #ECF3FA;
  }
  position: relative;
`

const DiscountImageWrapper = styled.div`
  width: 100%;
`
const DiscountImage = styled.img`
  object-fit: cover;
  border-radius: 7px 7px 0px 0px;
  width: 100%;
  ${media.desktop`
    max-height: ${pxToRem(100)};
  `}
  ${media.giant`
    max-height: ${pxToRem(130)};
  `}
`

const DiscountTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: ${pxToRem(16)};
  padding-right: ${pxToRem(16)};
  padding-top: ${pxToRem(15)};
  padding-bottom: ${pxToRem(15)};
`

const DiscountBadge = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: center;
  top: ${pxToRem(5)};
  right: ${pxToRem(5)};
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 3px;
  height: ${pxToRem(23)};
  padding-left: ${pxToRem(10)};
  padding-right: ${pxToRem(10)};
`

const DiscountBadgeText = styled.div`
  font-size: ${props => props.theme.fontSizes.smallx};
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
  text-transform: uppercase;
  line-height: 1.5;
`

const DiscountTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.normal};
  font-weight: 700;
  letter-spacing: ${pxToRem(-0.05)};
  color: ${props => props.theme.colors.primary};
  ${media.desktop`
    line-height: ${pxToRem(26)};
  `}
  ${media.xl`
    line-height: initial;
  `}
`

const DiscountLink = styled.a`
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  letter-spacing: 0px;
  color: ${props => props.theme.colors.info};
  display: flex;
  align-items: center;
  ${DiscountWrapper}:hover & {
    color: ${props => props.theme.colors.warning};
  }
`

const DiscountLinkIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.medium};
`

const DiscountLinkText = styled.span`
  margin-left: ${pxToRem(7)};
  text-decoration: underline;
`

const LatestDiscounts: FC<LatestDiscountsProps> = ({ listingUrl, discounts }) => {
  const { isDesktop } = useApp()
  const { session } = useUser()

  if (!discounts || (Array.isArray(discounts) && discounts.length === 0)) {
    return null
  }

  const discountItems = discounts.length > 2 ? discounts.slice(0, 2) : discounts

  const headerLink: HeaderLinkInterface | null = listingUrl
    ? {
        url: listingUrl,
        text: 'View all discounts',
        icon: faTag,
        scroll: true,
      }
    : null

  return (
    <GenericCard headerTitle="Latest discounts" headerLink={headerLink}>
      {isDesktop ? (
        <Wrapper>
          {discountItems?.map((discountItem, index) => (
            <Link href={slugToBranchPrefix(discountItem.discountLink, 'benefit', session)} passHref legacyBehavior key={index}>
              <DiscountWrapper>
                <DiscountBadge>
                  <DiscountBadgeText>Discounts</DiscountBadgeText>
                </DiscountBadge>
                <DiscountImageWrapper>
                  <DiscountImage src={discountItem.discountImage} />
                </DiscountImageWrapper>
                <DiscountTitleWrapper>
                  <DiscountTitle>{discountItem.discountTitle}</DiscountTitle>
                  <DiscountLink>
                    <DiscountLinkIcon icon={discountItem.discountIsExternal ? faExternalLinkSquareAlt : faTag} />
                    <DiscountLinkText>{discountItem.discountLinkText ?? 'Learn more'}</DiscountLinkText>
                  </DiscountLink>
                </DiscountTitleWrapper>
              </DiscountWrapper>
            </Link>
          ))}
        </Wrapper>
      ) : <MobileLatestDiscounts discountItems={discountItems} />}
    </GenericCard>
  )
}

export default LatestDiscounts
