import { FC, PropsWithChildren, memo } from 'react'
import hash from 'stable-hash'
import SiteContainer from '@app/ui/components/SiteContainer'
import UI from '@app/ui'

type ContainerProps = PropsWithChildren<{}>

// Component
const AppLayoutImpl: FC<ContainerProps> = ({
  children,
}) => {
  return (
    <UI>
      <SiteContainer>
        {children}
      </SiteContainer>
    </UI>
  )
}

// "Should update"
const areEqual = (
  prevProps: ContainerProps,
  nextProps: ContainerProps,
) => hash(prevProps.children) === hash(nextProps.children)

// Compose into a new component
// eslint-disable-next-line react/display-name
export const AppLayout: FC<ContainerProps> = memo(
  AppLayoutImpl,
  areEqual,
)

export default AppLayout
