import {default as NextLink} from 'next/link'
import { ComponentProps, MouseEventHandler } from 'react'

export type LinkProps = ComponentProps<typeof NextLink> & {
  onClick?: MouseEventHandler<HTMLAnchorElement>
  href: string
  children: any
  target?: string
  passHref?: boolean
  legacyBehavior?: boolean
}

const Link = ({ href, target, children, passHref, onClick, legacyBehavior = true, ...props }: LinkProps) => {
  return (
    <NextLink href={href} passHref={passHref} legacyBehavior={legacyBehavior} {...props}>
      {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
      }<a target={target} onClick={onClick}>
        {children}
      </a>
    </NextLink>
  )
}

export default Link
