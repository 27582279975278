import React, { FC } from 'react'
import Heading from '@ui/components/Heading'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { useUser } from '@app/lib/hooks/useUser'
import { useAppSelector } from '@app/store'
import MemberNumber from '../MemberNumber'

const Container = styled.div`
  display: none;
  ${media.desktop`
    display: flex;
    flex-direction: column;
  `}
`

const DesktopDashboardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledH3 = styled.h3`
  margin: 0;
  color: ${props => props.theme.colors.info};
  font-family: ${props => props.theme.fonts.intro};
  font-weight: 900;
  text-transform: uppercase;
  font-size: ${(props: any) => props.theme.fontSizes.smallx};
  ${media.desktop`
    font-size: ${(props: any) => props.theme.fontSizes.medium};
  `}
`

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Avatar = styled.img`
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 9999px;
  border: 2px solid #FFFFFF;
  object-fit: cover;
`

const ProfileContent = styled.div`
  margin-left: 1rem;
`

const StyledName = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.medium};
  color: ${props => props.theme.colors.primary};
  font-weight: 900;
`

const StyledTitle = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.default};
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
`

const StyledHr = styled.div`
  border-bottom: 1px solid #C5D9E580;
  margin-top: 1.875rem;
`

const renderBanner = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { branchSettings } = useAppSelector(state => state.app)

  return (
    <ProfileWrapper>
      {branchSettings?.secretaryImage && <Avatar src={branchSettings?.secretaryImage} />}
      <ProfileContent>
        {branchSettings?.secretaryName && <StyledName>{branchSettings?.secretaryName}</StyledName>}
        {branchSettings?.secretaryTitle && <StyledTitle>{branchSettings?.secretaryTitle}</StyledTitle>}
      </ProfileContent>
    </ProfileWrapper>
  )
}

const DesktopDashboardHeader: FC = () => {
  const { session } = useUser()

  return (
    <Container>
      <DesktopDashboardHeaderWrapper>
        <div>
          <StyledH3>Welcome back,</StyledH3>
          <Heading headingType="h1" heading={session?.data && `${session.data?.given_name} ${session.data?.family_name}`} />
          <MemberNumber />
        </div>
        {renderBanner()}
      </DesktopDashboardHeaderWrapper>
      <StyledHr />
    </Container>
  )
}

export default DesktopDashboardHeader
