
import React, { useContext, useEffect, useMemo } from 'react'
import { WordPressPage } from '@app/lib/graphql/wordpressClient'
import { NextRouter, useRouter } from 'next/router'

import { Settings } from '@app/lib/context/Settings'
import { useUser } from '@app/lib/hooks/useUser'

import { AppLayout } from '@app/ui/layouts/App'
import { PageContent } from '@app/ui/components/PageContent'
import { DashboardPage } from '@app/ui/components/Fragments/dashboard'

import { Spinner } from '@app/ui/components/Spinner'
import { Page } from '@app/email/forms/adminNotification/types'
import { PageWrapper } from '@app/ui/components/PageWrapper'
import { withSessionSSR } from '@app/lib/hoc/withSession'
import { GetServerSidePropsContext } from 'next'
import { serverSideProps } from '@app/lib/serverSideProps'
import { useTransition } from '@app/lib/hooks/useTransition'
import { TransitionLayout } from '@app/ui/layouts/TransitionLayout'
import { LoaderOverlay } from '../LoaderOverlay'
import { useWidgetsLoaded } from '@app/lib/hooks/useWidgetsLoaded'
import { useEjectTriggers } from '@app/lib/hooks/useEjectTriggers'
import { useLogout } from '@app/lib/hooks/useLogout'
import { useAppSelector } from '@app/store'
import { saveMemberOnboarded, verifyMemberOnboarded } from '@app/store/slices/auth-slice'
import { useDispatch } from 'react-redux'
import Modal from '../Modal'
import { useOnlineStatus } from '@app/lib/hooks/useOnlineStatus'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import * as Sentry from '@sentry/nextjs'

export type RouterProps = {
  preview: boolean
  slug: string
  status?: string
  page?: WordPressPage & Record<string, any>
  primaryMenu?: any
  mobileMenu?: any
  issuesAtWorkEnabled?: boolean
  children?: any
  formJson?: {
    pages?: Page[]
  }
  legalNotices?: any
}

const ModalHeader = styled.h2`
  margin: .5rem 0 1.5rem 0;
  ${media.xs`
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-right: 3rem;
  `}
`

const ModalBody = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
`

export const Router: React.FC<RouterProps> = ({
  slug,
  status,
  preview,
  children,
}: RouterProps) => {
  const { session, isValidating } = useUser({ revalidate: true })
  const performLogout = useLogout()
  const router = useRouter()
  const { transitionState } = useTransition()
  const { widgetsLoaded } = useWidgetsLoaded()
  const setupComplete = Boolean(useContext(Settings.State))
  const { onboarded } = useAppSelector(state => state.auth)
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const onlineStatus = useOnlineStatus()
  const isLoggedIn = useMemo(() => session?.isLoggedIn ?? false, [session])

  useEffect(() => {
    if (!preview && !isValidating) {
      if (!isLoggedIn) {
        performLogout().then(() => {
          // Set the requested pathname in session storage for if the user logs back in
          window.sessionStorage.setItem('sda-requested-href', router.asPath)

          void router.replace('/login')
        })
      } else {
        const requestedLink = window.sessionStorage.getItem('sda-requested-href')

        if (requestedLink) {
          window.sessionStorage.removeItem('sda-requested-href')

          router.push(requestedLink).catch((err) => {
            Sentry.captureException(err)
          })
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview, isLoggedIn, router, isValidating])

  useEffect(() => {
    if (!preview && !isValidating) {
      if (session?.isLoggedIn && onlineStatus !== 'offline') {
        dispatch(verifyMemberOnboarded())
      }
    }
  }, [
    preview,
    session?.isLoggedIn,
    isValidating,
    dispatch,
    onlineStatus,
  ])

  const { dirty } = useEjectTriggers()

  const ActiveLayout = setupComplete ? AppLayout : TransitionLayout

  return !dirty && (preview || session?.isLoggedIn) ? (
    <ActiveLayout>
      <>
        {!preview && !Boolean(status) && !Boolean(slug) ?
          <DashboardPage /> :
          (<PageContent>
            <PageWrapper>
              {children}
            </PageWrapper>
          </PageContent>)}
        <LoaderOverlay show={transitionState === 'fadeOut' || !widgetsLoaded} />
        <Modal
          show={onboarded===false}
          onClose={() => {
            dispatch(saveMemberOnboarded())
          }}
          closeOnOverlayClick
        >
          <ModalHeader>Welcome to the SDA Members Area.</ModalHeader>

          <ModalBody>
            <strong>This is a resource exclusive to SDA members.</strong>
            <br/><br/>

            Here you&apos;ll find information about your pay and conditions that are specific to you.

            You&apos;ll also find all of our latest news, events and campaigns.

            <br /><br/>If something doesn&apos;t look right about your workplace information, get in touch with us so we can help.
          </ModalBody>
        </Modal>
      </>
    </ActiveLayout>
  ) : <div><Spinner /></div>
}

export const getServerSideProps = withSessionSSR(async function getServerSideProps({
  req,
  params,
  query,
}: GetServerSidePropsContext<any>) {
  return serverSideProps({ req, params, query })
})
