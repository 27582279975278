import { useApp } from '@app/lib/hooks/app'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { FC } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import GenericCard from '@ui/components/GenericCard'
import { HeaderLinkInterface } from '@ui/components/GenericCard/types'
import { EventCardProps, UpcomingEventsProps } from './types'
import MobileUpcomingEvents from './MobileUpcomingEvents'
import pxToRem from '@app/ui/utils/pxToRem'
import Link from 'next/link'
import { useUser } from '@app/lib/hooks/useUser'
import { slugToBranchPrefix } from '@app/ui/utils/slugToBranchPrefix'
import React from 'react'
import { useAppSelector } from '@app/store'

const Container = styled.div`
  display: none;
  ${media.desktop`
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(15)};
    min-height: ${pxToRem(340)};
  `}
`

const EventWrapper = styled.div`
  display: flex;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
    background-color: #ECF3FA;
  }
  height: ${pxToRem(164)};
`

const EventImageWrapper = styled.div`
  flex: 1;
  min-width: ${pxToRem(149)};
  max-width: ${pxToRem(149)};
  overflow: hidden;
`
const EventImage = styled.img`
  min-width: ${pxToRem(149)};
  height: 100%;
  object-fit: cover;
  border-radius: 7px 0px 0px 7px;
`

const EventTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: ${pxToRem(20)};
  padding-right: ${pxToRem(20)};
  position: relative;
`

export const FreeBadge = styled.div`
  position: absolute;
  top: ${pxToRem(5)};
  right: ${pxToRem(5)};
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding-left: ${pxToRem(5)};
  padding-right: ${pxToRem(5)};
  padding-top: ${pxToRem(3)};
  padding-bottom: ${pxToRem(3)};
  ${media.desktop`
    top: ${pxToRem(10)};
    right: ${pxToRem(10)};
    padding-left: ${pxToRem(10)};
    padding-right: ${pxToRem(10)};
    padding-top: ${pxToRem(4)};
    padding-bottom: ${pxToRem(4)};
  `}
`

export const FreeBadgeText = styled.div`
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
  text-transform: uppercase;
  line-height: 1.5;
  ${media.desktop`
    font-size: ${(props: any) => props.theme.fontSizes.smallx};
  `}
`

const EventDate = styled.div`
  font-size: ${props => props.theme.fontSizes.small};
  color: #898989;
  line-height: 1;
  ${media.desktop`
    margin-bottom: ${pxToRem(11)};
    font-size: ${(props: any) => props.theme.fontSizes.smallx};
  `}
`

const EventTitle = styled.div`
  font-size: ${pxToRem(15)};
  font-weight: 700;
  letter-spacing: -0.04px;
  color: ${props => props.theme.colors.primary};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${media.desktop`
    margin-bottom: ${pxToRem(15)};
    font-size: ${(props: any) => props.theme.fontSizes.medium};
  `}
`

const EventLink = styled.a`
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1;
  color: ${props => props.theme.colors.info};
  display: flex;
  align-items: center;
  ${EventWrapper}:hover & {
    color: ${props => props.theme.colors.warning};
  }
`

const EventLinkText = styled.span`
  text-decoration: underline;
`

export const EventCard: FC<EventCardProps> =
  // eslint-disable-next-line react/display-name
  React.forwardRef(({ eventItem, onClick, href }, ref) => {
    const { branchSettings } = useAppSelector(state => state.app)

    return <EventWrapper
      onClick={onClick}
      ref={ref as React.MutableRefObject<HTMLDivElement>}
      style={{ cursor: eventItem.isCancelled ? 'auto' : 'pointer' }}
    >
      <EventImageWrapper>
        <EventImage src={eventItem.eventImage ?? branchSettings?.eventsListingFallbackFeaturedImage?.mediaItemUrl} />
      </EventImageWrapper>
      <EventTitleWrapper>
        {eventItem.isFree && (
          <FreeBadge>
            <FreeBadgeText>Free Event</FreeBadgeText>
          </FreeBadge>
        )}
        <EventDate style={{ color: eventItem.isCancelled ? '#d23d4b' : '#898989' }}>
          {eventItem.eventStartDate}
          {eventItem.eventStartDate?.length > 0 && eventItem.eventEndDate?.length > 0 ? <> - </> : null}
          {eventItem.eventEndDate}
        </EventDate>
        <EventTitle>{eventItem.eventTitle}</EventTitle>
        {!eventItem.isCancelled && <EventLink href={href}>
          <EventLinkText>{eventItem.eventType === 'ticketed' ? 'Get tickets' : 'RSVP to event'}</EventLinkText>
        </EventLink>}
      </EventTitleWrapper>
    </EventWrapper>
  })

const UpcomingEvents: FC<UpcomingEventsProps> = ({ eventsListingLink, events }) => {
  const { isDesktop } = useApp()
  const { session } = useUser()

  if (!events || (Array.isArray(events) && events.length === 0)) {
    return null
  }

  const eventItems = events.length > 2 ? events.slice(0, 2) : events

  const headerLink: HeaderLinkInterface | null = eventsListingLink
    ? {
      url: eventsListingLink,
      text: 'View all events',
      icon: faCalendar,
      scroll: true,
    }
    : null

  return (
    <GenericCard headerTitle="Upcoming events" headerLink={headerLink}>
      {isDesktop ? (
        <Container>
          {eventItems?.map((eventItem, index) => {
            return eventItem.isCancelled ?
              <EventCard eventItem={eventItem} key={`${index}${eventItem.eventLink}`} />
              :
              <Link
                scroll={false}
                href={slugToBranchPrefix(eventItem.eventLink, 'event', session) ?? '#'}
                passHref legacyBehavior
                key={`${index}${eventItem.eventLink}`}
              >
                <EventCard eventItem={eventItem} />
              </Link>
          })}
        </Container>
      ) : <MobileUpcomingEvents eventItems={eventItems} />}
    </GenericCard>
  )
}

export default UpcomingEvents
