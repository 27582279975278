import React from 'react'
import styled from 'styled-components'
import { StyledLink } from '../Link/StyledLink'
import pxToRem from '@app/ui/utils/pxToRem'
import { media } from 'styled-bootstrap-grid'

const TitleContainer = styled.div`
  display: flex;
  flex-direction : row;
  justify-content: space-between;
  height: 2.8125rem ;
  font-weight: 700;
  align-items: center;
`

const LinkContainer = styled(StyledLink)`
  display: flex;
  flex: 1;
  flex-direction : row;
  justify-content: space-between;
  height: 2.8125rem ;
  font-weight: 700;
  align-items: center;
  padding: ${pxToRem(11)} ${pxToRem(15)};
  height: auto;
  ${media.desktop`
    padding: ${pxToRem(13)} ${pxToRem(15)} ${pxToRem(13)} ${pxToRem(10)};
  `}
`

const ContainerItem = styled.div`
display: flex;
flex: 1,
`

const LeftIcon = styled.div`
margin-right: ${pxToRem(10)};
`

const RightIcon = styled.div`
  margin-left: 0.875rem;
  color: #012F57;
`

const ItemTitle = styled.div`
  font-size: ${pxToRem(16)};
  color: #012F57;
  flex: 1;
  line-height: 1;
`

const Container = styled.div`
  cursor: pointer;
  border-radius: 0.4375rem;
  background-color : #ECF3FA;
  & + & {
    margin-top: ${pxToRem(13)};
  }
  &:hover, &:focus, &:active {
    ${LinkContainer} {
      border-radius: 0.4375rem;
      background-color: #F3F3F3;
    }
    ${RightIcon}, ${LeftIcon} {
      filter: invert(50%) sepia(50%) saturate(2660%) hue-rotate(344deg) brightness(99%) contrast(106%);
    }
  }
`

export type ButtonWithIconProps = {
  path?: string,
  title?: string,
  leftIcon?: React.ReactNode,
  getProps?: any,
  rightIcon?: React.ReactNode,
  children?: any
}

export const ButtonWithIcon = ({ path, title, children, leftIcon, rightIcon }: ButtonWithIconProps) => {
  return (
    <Container>
      {
        path ?
          <LinkContainer href={path}>
            {leftIcon && <LeftIcon> {leftIcon}</LeftIcon>}
            <ItemTitle>
              {title}
            </ItemTitle>
            {rightIcon && <RightIcon>{rightIcon}</RightIcon>}
          </LinkContainer> :
          <TitleContainer>
            <ContainerItem>
              {leftIcon && <LeftIcon> {leftIcon}</LeftIcon>}
              <ItemTitle>
                {children || title}
              </ItemTitle>
            </ContainerItem>
            {rightIcon && <RightIcon>{rightIcon}</RightIcon>}
          </TitleContainer>
      }
    </Container>
  )
}
export default ButtonWithIcon
