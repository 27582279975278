//-----------------
// This component wraps the three 'payrates widgets' at the top of the dashboard.
// The widget is not used on the 'Your award or agreement' page.
//-----------------

import pxToRem from '@app/ui/utils/pxToRem'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import WorkplaceInfoIndicator from '@ui/components/WorkplaceInfoIndicator'
import PayRateIndicator from '@ui/components/PayRateIndicator'
import AwardIndicator from '@ui/components/AwardIndicator'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@app/store'
import { useEffect, useState, FC, useMemo } from 'react'
import {
  IEmployer,
  setEmployerId,
} from '@app/store/slices/employer-slice'
import { EmployerDataLoader } from '@ui/components/EmployerDataLoader'
import { extractAwardIndicatorFromRawEbaData, getEbaData } from '@app/lib/formatters/awards'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    margin-top: ${pxToRem(26.5)};
  `}
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    row-gap: ${pxToRem(10)};
  `}
`

const HeaderTitle = styled.div`
  font-size: ${pxToRem(15)};
  font-weight: 900;
  color: ${props => props.theme.colors.primary};
  letter-spacing: 0px;
  ${media.desktop`
    font-size: ${pxToRem(25)};
    letter-spacing: -0.5px;
    margin-right: ${pxToRem(26)};
  `}
`

const IndicatorWrapper = styled.div`
  margin-top: ${pxToRem(12)};
  display: grid;
  gap: ${pxToRem(15)};
  ${media.giant`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: ${pxToRem(20)};
  `}
`

interface YourWorkplaceInformationProps {
  hideAwardIndicator?: boolean
  showOnlyAwardIndicator?: boolean
}

const YourWorkplaceInformation: FC<YourWorkplaceInformationProps> =
  ({ hideAwardIndicator, showOnlyAwardIndicator }) => {
  const dispatch = useDispatch()

  const {
    employers,
    currentEmployerId,
    payRateData,
  } = useAppSelector(state => state.employers)

  const {
    ebasData,
  } = useAppSelector(state => state.ebas)

  const { siteSettings } = useAppSelector(state => state.app)

  const correctEbaData = useMemo(() => getEbaData(employers, currentEmployerId, payRateData, ebasData, siteSettings), [employers, currentEmployerId, payRateData, ebasData, siteSettings])

  const awardIndicator = extractAwardIndicatorFromRawEbaData(correctEbaData)

  const [currentEmployer, setCurrentEmployer] = useState<IEmployer | null>(null)

  useEffect(() => {
    let employerId
    if (!currentEmployerId) {
      employerId = employers?.[0]?.id
      dispatch(setEmployerId(employerId))
    }

    setCurrentEmployer(employers?.find((element : any) => element.id === currentEmployerId))
  }, [employers, currentEmployerId, dispatch])

  if(showOnlyAwardIndicator) {
    return (<AwardIndicator {...awardIndicator} />)
  }

  return (
    <Container>
      <EmployerDataLoader/>
      <HeaderWrapper>
        <HeaderTitle>Your workplace information</HeaderTitle>
      </HeaderWrapper>
      <IndicatorWrapper>
        <PayRateIndicator {...{ payRateData }} />
        {!hideAwardIndicator &&
          <AwardIndicator {...awardIndicator} />
        }
        <WorkplaceInfoIndicator {...currentEmployer?.workplaceInfo} />
      </IndicatorWrapper>
    </Container>
  )
}

export default YourWorkplaceInformation
