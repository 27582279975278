import React, { FC } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { FeaturedCampaignProps } from './types'
import pxToRem from '@app/ui/utils/pxToRem'
import ButtonLink from '../ButtonLink'

const CTAImageWrapper = styled.div`
  border-radius: 7px 7px 0 0;
  display: flex;
  max-height: ${pxToRem(203)};
  width: 100%;
`

const CTAImage = styled.img`
  border-radius: 7px 7px 0 0;
  object-fit: cover;
  width: 100%;
  max-height: ${pxToRem(203)};
`

const CTABody = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${pxToRem(13)};
  padding-right: ${pxToRem(13)};
  padding-top: ${pxToRem(16)};
  padding-bottom: ${pxToRem(16)};
  background: #FFFFFF;
  border-radius: ${pxToRem(0)} ${pxToRem(0)} ${pxToRem(7)} ${pxToRem(7)};
  border: 1px solid #fff;
  transition: all 0.3s linear;

  ${media.desktop`
    padding-left: ${pxToRem(19)};
    padding-right: ${pxToRem(19)};
    padding-top: ${pxToRem(26)};
    padding-bottom: ${pxToRem(26)};
  `}
`

const Container = styled.a`
  box-shadow: 0px 0px 20px #b7d4e54d;
  width: 100%;

  &:hover {
    ${CTABody} {
      background: #ECF3FA;
      border: 1px solid #A8DFFF80;
    }

    div > div > a {
      background-color: #f2560b;
      color: white;
    }
  }

  ${CTABody} > div > a {
    letter-spacing: ${pxToRem(0.7)};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(17)};
    font-weight: normal;
  }
`

const Title = styled.div`
  font-size: ${pxToRem(15)};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  line-height: 1.333333333;

  ${media.desktop`
    font-size: ${pxToRem(18)};
  `}
`

const Summary = styled.div`
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  color: #4a4a4a;
  margin-top: ${pxToRem(10)};
  line-height: 1.42857143;
  ${media.desktop`
    font-size: ${pxToRem(16)};
    margin-top: ${pxToRem(13)};
    line-height: 1.5;
  `}
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${pxToRem(16)};
  ${media.desktop`
    margin-top: ${pxToRem(25)};
  `}
  flex-wrap: wrap;
  gap: ${pxToRem(15)};
`

const FeaturedCampaign: FC<FeaturedCampaignProps> = ({
  campaignImage,
  campaignTitle,
  campaignSummary,
  campaignCTAText,
  campaignLink,
  campaignTarget,
}) => {
  if (!campaignImage || !campaignTitle || !campaignLink) {
    return null
  }

  return (
    <Container href={campaignLink}>
      <CTAImageWrapper>
        <CTAImage src={campaignImage} />
      </CTAImageWrapper>
      <CTABody>
        <Title>{campaignTitle}</Title>
        {campaignSummary && <Summary>{campaignSummary}</Summary>}
        <ButtonWrapper>
          {campaignLink && (
            <ButtonLink href={campaignLink} target={campaignTarget}>{campaignCTAText}</ButtonLink>
          )}
        </ButtonWrapper>
      </CTABody>
    </Container>
  )
}

export default FeaturedCampaign
