import { useEffect } from 'react'
import { useAppSelector } from '@app/store'
import { closeMobileNavMenu, setAbsoluteMenuIndex, setOpenMenuKey } from '@app/store/slices/app-slice'
import pxToRem from '@app/ui/utils/pxToRem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { media } from 'styled-bootstrap-grid'
import styled, { createGlobalStyle } from 'styled-components'
import { LeftSection } from '../MenuHeader/LeftSection'
import { NoOverlaySpinner } from '../Spinner'
import CtaItems from './CtaItems'
import MenuItems from './MenuItems'
import ResponsiveMenuItems from './ResponsiveMenuItems'
import { MenuItemInterface, PrimaryNavMenuProps } from './types'

const InertBodyStyle = createGlobalStyle`
  body {
    overflow: hidden;
    ${media.desktop`
      overflow: auto;
    `}
  }
`

const PrimaryNavMenuContainer = styled.div`
  display: none;
  background-color: #fff;

  ${media.desktop`
    display: flex;
    flex-direction: column;
    width: ${pxToRem(310)};
    min-width: ${pxToRem(310)};
    padding-bottom: ${pxToRem(55)};
  `}
`

const ResponsiveNavMenuContainer = styled.div`
  background-color: ${props => props.theme.header.backgroundColor};
  height: 100vh;
  padding-bottom: ${pxToRem(20)};
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  ${media.desktop`
    display: none;
  `}
  @keyframes fadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  };
`

const ResponsiveNavMenuWrapper = styled.div`
  background-color: #e5eef7;
  position: relative;
  top: ${pxToRem(20)};
  left: 0;
  width: 100%;
  ${media.desktop`
    display: none;
  `}
  height: 93%;
  z-index: 9999;
  padding-top: ${pxToRem(12)};
  display: flex;
  flex-direction: column;
`

const CloseButton = styled.button`
  position: absolute;
  top: ${pxToRem(-20)};
  right: ${pxToRem(16)};
  background-color: #ff6820;
  box-shadow: 0px 0px 20px #b7d4e54d;
  border-radius: 9999px;
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;

  &:hover {
    background-color: #f2560b;
  }
`

const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: ${pxToRem(27)};
`

const CustomSpinner = styled(NoOverlaySpinner)`
  position: sticky;
  top: max(31%, 144px);
`

const ScrollableWrapper = styled.div`
  overflow-y: scroll;
  padding-bottom: ${pxToRem(100)};
`

const PrimaryNavMenu: FC<PrimaryNavMenuProps> = ({ menuItems, ctaItems, loading = false }) => {
  const dispatch = useDispatch()
  const { mobileNavMenuIsOpened, openMenuKey } = useAppSelector((state: any) => state.app)
  const router = useRouter()

  const performCloseMobileNavMenu = useCallback(() => {
    dispatch(closeMobileNavMenu())
    setOpenMenuKey(null)
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('beforeunload', performCloseMobileNavMenu)

    return () => {
      window.removeEventListener('beforeunload', performCloseMobileNavMenu)
    }
  }, [performCloseMobileNavMenu])

  const selectMenuItem = useCallback((
    menuItem: MenuItemInterface,
    index: number | null,
    absoluteIndex: number,
    isTopLevelEntry = false,
  ) => {
    if (!isTopLevelEntry) {
      dispatch(
        setAbsoluteMenuIndex(
          absoluteIndex >= 0 ? absoluteIndex : menuItems?.find((menuItem: any) => menuItem.path === router.asPath + '/')
        )
      )
    }

    if (index !== null) {
      if (isTopLevelEntry && openMenuKey === index) {
        dispatch(setOpenMenuKey(null))
      } else {
        dispatch(setOpenMenuKey(index))
      }
    }

    if (menuItem.itemUrl && menuItem?.subItems?.length === 0) {
      return router.push(menuItem.itemUrl)
    }
  }, [dispatch, menuItems, openMenuKey, router])


  if (menuItems?.length === 0 && ctaItems?.length === 0) {
    return null
  }

  return (
    <>
      <PrimaryNavMenuContainer>
        {loading ?
          <CustomSpinner /> :
          <>
            <MenuItems
              menuItems={menuItems}
              openKey={openMenuKey}
              selectMenuItem={selectMenuItem}
            />
            <CtaItems ctaItems={ctaItems} />
          </>
        }
      </PrimaryNavMenuContainer>
      {mobileNavMenuIsOpened && (
        <ResponsiveNavMenuContainer>
          <LeftSection />
          <InertBodyStyle />
          <ResponsiveNavMenuWrapper>
            <CloseButton onClick={performCloseMobileNavMenu}>
              <CloseIcon icon="times" />
            </CloseButton>
            <ScrollableWrapper>
              <ResponsiveMenuItems
                menuItems={menuItems}
                openKey={openMenuKey}
                selectMenuItem={selectMenuItem}
              />
              <CtaItems ctaItems={ctaItems} />
            </ScrollableWrapper>
          </ResponsiveNavMenuWrapper>
        </ResponsiveNavMenuContainer>
      )}
    </>
  )
}

export default PrimaryNavMenu
