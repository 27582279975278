import pxToRem from '@app/ui/utils/pxToRem'
import { faExternalLinkSquareAlt, faTag } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { FC } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import SlickSlider from '@ui/components/SlickSlider'
import { DiscountInterface } from './types'
import { slugToBranchPrefix } from '@app/ui/utils/slugToBranchPrefix'
import { useUser } from '@app/lib/hooks/useUser'

const Wrapper = styled.div`
  min-height: ${pxToRem(110)};
`

const DiscountWrapper = styled.div`
  display: flex;
  position: relative;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
    background-color: #ECF3FA;
  }
  ${media.desktop`
    display: none;
  `}
`

// const DiscountBadge = styled.div`
//   position: absolute;
//   display: inline-flex;
//   align-items: center;
//   top: ${pxToRem(5)};
//   right: ${pxToRem(5)};
//   background: #FFFFFF 0% 0% no-repeat padding-box;
//   border-radius: 3px;
//   padding-left: ${pxToRem(10)};
//   padding-right: ${pxToRem(10)};
//   padding-top: ${pxToRem(4)};
//   padding-bottom: ${pxToRem(4)};
//   height: ${pxToRem(23)};
// `

// const DiscountBadgeText = styled.div`
//   font-size: ${props => props.theme.fontSizes.small};
//   font-weight: 500;
//   color: ${props => props.theme.colors.primary};
//   text-transform: uppercase;
//   line-height: 1.5;
// `

const DiscountImageWrapper = styled.div`
`
const DiscountImage = styled.img`
  width: ${pxToRem(99)};
  height: ${pxToRem(89)};
  object-fit: cover;
  border-radius: 7px 0px 0px 7px;
`

const DiscountTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: ${pxToRem(13)};
  padding-right: ${pxToRem(13)};
`

const DiscountTitle = styled.div`
  font-size: ${pxToRem(15)};
  font-weight: 700;
  letter-spacing: ${pxToRem(-0.04)};
  color: ${props => props.theme.colors.primary};
`

const DiscountLink = styled.a`
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  letter-spacing: 0px;
  color: ${props => props.theme.colors.info};
  display: flex;
  align-items: center;
  ${DiscountWrapper}:hover & {
    color: ${props => props.theme.colors.warning};
  }
`

const DiscountLinkIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.normal};
`

const DiscountLinkText = styled.span`
  margin-left: ${pxToRem(7)};
  text-decoration: underline;
`

interface MobileLatestDiscountsProps {
  discountItems: DiscountInterface[]
}

const MobileLatestDiscounts: FC<MobileLatestDiscountsProps> = ({ discountItems }) => {
  const { session } = useUser()

  return (
    <Wrapper>
      <SlickSlider>
        {discountItems?.map((discountItem, index) => (
          <div key={index}>
            <Link href={slugToBranchPrefix(discountItem.discountLink, 'benefit', session) ?? '#'} passHref legacyBehavior>
              <DiscountWrapper>
                <DiscountImageWrapper>
                  <DiscountImage src={discountItem.discountImage} />
                </DiscountImageWrapper>
                <DiscountTitleWrapper>
                  <DiscountTitle>{discountItem.discountTitle}</DiscountTitle>
                  <DiscountLink>
                    <DiscountLinkIcon icon={discountItem.discountIsExternal ? faExternalLinkSquareAlt : faTag} />
                    <DiscountLinkText>{discountItem.discountLinkText ?? 'Learn more'}</DiscountLinkText>
                  </DiscountLink>
                </DiscountTitleWrapper>
              </DiscountWrapper>
            </Link>
          </div>
        ))}
      </SlickSlider>
    </Wrapper>
  )
}

export default MobileLatestDiscounts
