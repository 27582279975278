import {
  AuthedClient,
} from './graphql/wordpressClient'

// @ts-ignore
import { branchPrefixes } from './constants'
import { DynamicPageProps } from '@app/pages/[[...slug]]'
import { getActiveBranchFromSession, getActiveLocationFromSession } from './utils'

export const exclusions = [
  'computeMetadata/v1/project/project-id',
  'favicon.ico',
]

export const getAccessToken = (req: any, query: any) => {
  const user = req.session?.user
  let accessToken

  if (Boolean(query?.preview)) {
    accessToken = query?.token
  } else {
    accessToken = user?.accessToken
  }

  return accessToken
}

export const getPageId = (query: any, fullSlug: string) => {
  const preview = Boolean(query?.preview)
  let pageId

  if (preview) {
    pageId = query?.preview_id ?? query?.page_id
  } else {
    pageId = fullSlug ?? '/'
  }

  return pageId
}

export const serverSideProps = async (
  { req, params, query } : { req: any, params?: any, query: any }
  ) => {
  const { slug } = params
  const fullSlug = slug?.join('/')
  if (exclusions.includes(fullSlug)) return { props: {} }

  let pageId = getPageId(query, fullSlug)
  const preview = Boolean(query?.preview)
  const preview_nonce = query?.preview_nonce
  const accessToken = getAccessToken(req, query)
  // get the user's branch
  const activeBranch = getActiveBranchFromSession(req.session?.user)
  const activeLocation = getActiveLocationFromSession(req.session?.user)
  const activeId = req.session?.user?.data.activeId
  const authedClient = new AuthedClient(
    accessToken,
    activeId,
    activeBranch,
    activeLocation,
  )

  const branchPrefix = branchPrefixes.find(el => el === (fullSlug?.match(/^\w+/g))?.[0])

  let props: Partial<DynamicPageProps> = {
    preview,
    slug: fullSlug ?? null,
  }

  // handle branch-specific rewrites
  if (!preview) {
    if (!branchPrefix || branchPrefix === 'national') {
      const scopedSlug = fullSlug?.replace('national/', '')

      // rewrite the slug
      const maybeSlug = `${activeBranch?.toLowerCase() ?? ''}-${scopedSlug}`

      // probe against the user's branch
      const branchVersionExists = scopedSlug ? Boolean((await authedClient.checkPageExists(maybeSlug))?.page?.slug) : false

      // if it exists, redirect
      if (branchVersionExists) {
        return {
          redirect: {
            destination: `/${activeBranch?.toLowerCase() ?? ''}/${scopedSlug}`,
            permanent: false,
          },
        }
      }
    } else if (activeBranch && fullSlug) {
      if (branchPrefix !== activeBranch.toLowerCase()) {
        const slugArr = fullSlug.split('/')
        const slugPrefix = slugArr[0]
        const slugWithoutPrefix = fullSlug.replace(slugPrefix + '/', '')

        return {
          redirect: {
            destination: `/${activeBranch.toLowerCase()}/${slugWithoutPrefix}`,
            permanent: false,
          },
        }
      }
    }
    pageId = branchPrefix ? fullSlug.replace(`${branchPrefix}/`, `${branchPrefix}-`) : `national-${fullSlug}` ?? '/'
  }

  // if an actual page (not /)
  if (preview || fullSlug) {
    if (preview) {
      const { type } = query
      const queryString = req.url.split('?')?.[1]

      let sub = ''

      switch (type) {
        case 'benefit':
          sub = 'benefits'
          break
        case 'event':
          sub = 'events'
          break
        case 'notice':
          sub = 'legal-notices'
          break
        case 'newsarticle':
          sub = 'news'
          break
        case 'campaign':
          sub = 'campaigns'
          break
        default:
          break
      }

      if (type !== 'award' && sub.length > 0) {
        return {
          props,
          redirect: {
            destination: `/${activeBranch?.toLowerCase() ?? 'state'}/${sub}/preview?${queryString}`,
            permanent: false,
          },
        }
      } else if (type === 'award' &&! /^\/?.+\/your-award-or-agreement\/?.*/.test(fullSlug)) {
        return {
          props,
          redirect: {
            destination: `/${activeBranch?.toLowerCase() ?? 'state'}/your-award-or-agreement?${queryString}`,
            permanent: false,
          },
        }
      }
    }

    const subTypes = [
      'benefits',
      'campaigns',
      'events',
      'legal-notices',
      'news',
    ]

    const isSubtypeIndex = subTypes.some(element => fullSlug === element)

    if (isSubtypeIndex) {
      return {
        redirect: {
          destination: `/${activeBranch.toLowerCase()}/${fullSlug}`,
          permanent: false,
        },
      }
    }

    const isSubtype = subTypes.some(element => fullSlug?.includes(/^\w+\/(.+)\//.exec(element)?.[1]))

    if ((fullSlug || preview) && !isSubtype) {
      const result = await authedClient.getPage(pageId, preview, preview_nonce)
      if (!result.error) {
        props.page = result?.page
      }
    }
  }

  // FIXME: consider idiomatic 404, https://nextjs.org/docs/advanced-features/custom-error-page
  return {
    props,
  }
}
