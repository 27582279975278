import { FC, PropsWithChildren, useEffect, useRef } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import pxToRem from '@app/ui/utils/pxToRem'
import { Settings } from 'react-slick'
const Slider = dynamic(() => import('react-slick'))

const StyledSlider = styled(Slider)`
  ul.slick-dots {
    text-align: right;
    right: ${pxToRem(-4)};
    bottom: ${pxToRem(-13)};
    li {
      margin: 0;
      button:before {
        font-size: ${pxToRem(40)};
        color: ${props => props.theme.colors.warning};
      }
    }
  }
`

const settings: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  arrows: false,
}

const SlickSlider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const sliderRef = useRef<any>(null)

  useEffect(() => {
    if (sliderRef.current) sliderRef.current.retry()
  }, [sliderRef])

  return (
    <StyledSlider ref={sliderRef} {...settings}>
      {children}
    </StyledSlider>
  )
}

export default SlickSlider
