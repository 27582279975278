import pxToRem from '@app/ui/utils/pxToRem'
import { FC, PropsWithChildren } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { PrimaryGenericCardProps } from './types'
import Skeleton from 'react-loading-skeleton'

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`

const Container = styled.div<{
  backgroundColor?: string
}>`
  background: ${props => props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  box-shadow: 0px 0px 20px #e8e8e866;
  border: 1px solid #e2ecf2;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding-bottom: ${pxToRem(10)};
  ${media.desktop`
    padding-left: ${pxToRem(16)};
    padding-right: ${pxToRem(16)};
    padding-top: ${pxToRem(10)};
  `}
`

const CardHeaderWrapper = styled.div<{
  primaryHeader?: boolean
}>`
  padding-left: ${pxToRem(14)};
  padding-right: ${pxToRem(14)};
  padding-top: ${pxToRem(11)};
  padding-bottom: ${pxToRem(10.5)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: ${pxToRem(7)};
  border-top-right-radius: ${pxToRem(7)};
  background-color: ${(props: any) => props.theme.colors.primary};
  ${media.desktop`
    margin-left: ${pxToRem(-16)};
    margin-right: ${pxToRem(-16)};
    margin-top: ${pxToRem(-10)};
    padding: ${pxToRem(13)} ${pxToRem(16)} ${pxToRem(12)};
  `}
`

const CardHeaderTitle = styled.div`
  color: ${(props: any) => props.theme.colors.white};
  font-size: ${pxToRem(15)};
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 900;
  letter-spacing: 0px;
`

const CardBodyWrapper = styled.div`
  padding-top: ${pxToRem(15.5)};
  padding-left: ${pxToRem(14)};
  padding-right: ${pxToRem(14)};
  ${media.desktop`
    padding-top: ${pxToRem(22)};
    padding-left: 0;
    padding-right: 0;
    height: 100%;
  `}
`

const PrimaryGenericCard: FC<PropsWithChildren<PrimaryGenericCardProps>> = ({
  headerTitle,
  children,
  backgroundColor
}) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <CardHeaderWrapper>
        <CardHeaderTitle>
          {(Boolean(headerTitle) && headerTitle) || (
            <Skeleton
              count={1}
              width={100}
              height={20}
            />
          )}
        </CardHeaderTitle>
      </CardHeaderWrapper>
      <CardBodyWrapper>{children}</CardBodyWrapper>
    </Container>
  )
}

export default PrimaryGenericCard
