import React, { FC } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Heading from '@ui/components/Heading'
import { PageHeaderProps } from './types'
import { useAppSelector } from '@app/store'
import pxToRem from '@app/ui/utils/pxToRem'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledHeader = styled(Heading)`
${media.desktop`
  margin-bottom: ${pxToRem(10)};
`}
`

const SubHeading = styled.h3`
  margin: 0;
  font-family: ${props => props.theme.fonts.intro};
  color: ${props => props.theme.colors.info};
  font-size: ${(props: any) => props.theme.fontSizes.smallx};
  text-transform: uppercase;
  line-height: ${pxToRem(15)};

  ${media.desktop`
    line-height: ${pxToRem(28)};
    font-size: ${(props: any) => props.theme.fontSizes.medium};
  `}
`

const IntroText = styled.p`
  color: #4A4A4A;
  margin-top: ${pxToRem(10)};
  font-size: ${(props: any) => props.theme.fontSizes.small2x};
  font-weight: 500;
  margin-bottom: ${pxToRem(20)};
  line-height: ${pxToRem(20)};

  ${media.desktop`
    font-size: ${(props: any) => props.theme.fontSizes.normal};
    margin-bottom: ${pxToRem(10)};
    line-height: 1.75;
  `}
`

export const useActiveMenuLabel = (menuItems: any[]) => {
  const { absoluteMenuIndex } = useAppSelector(state => state.app)

  return menuItems?.[absoluteMenuIndex]?.label
}

export const PageHeader: FC<PageHeaderProps> = ({
  heading,
  subHeading,
  introText,
  dynamic,
}) => {
  const { menuLUT } = useAppSelector(state => state.app)
  const dynamicSubHeading = useActiveMenuLabel(menuLUT)
  const actualSubHeading = dynamic ? dynamicSubHeading ?? subHeading: subHeading

  return (
    <Wrapper>
      {Boolean(actualSubHeading) && <SubHeading>{actualSubHeading}</SubHeading>}
      <StyledHeader headingType="h1" heading={heading} />
      {Boolean(introText) && <IntroText>{introText}</IntroText>}
    </Wrapper>
  )
}

export default PageHeader
