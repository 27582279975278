
//----------------------
// A component that can be dropped in anywhere, whose sole responsibility is to ensure
// that the employer, pay and conditions, and EBA data for a user are loaded into Redux exactly once.
//----------------------

import { useDispatch } from 'react-redux'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { useAppSelector } from '@app/store'
import { useEffect } from 'react'
import {
  fetchEmployers,
  fetchPayAndConditions,
} from '@app/store/slices/employer-slice'
import { fetchEbas } from '@app/store/slices/ebas-slice'

export type EmployerDataLoaderProps = {}

export const EmployerDataLoader: React.FC<EmployerDataLoaderProps> = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()

  const {
    payRateData,
  } = useAppSelector(state => state.employers)

  useEffect(() => {
    dispatch(fetchEmployers())
    dispatch(fetchPayAndConditions())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let ebaIds : string[] = []
    if(payRateData) {
      for(let i = 0; i < payRateData.length; i++) {
        const possibleAssetId = payRateData[i]?.eba?.document?.asset
        if(possibleAssetId) {
          ebaIds.push(possibleAssetId)
        }
      }
    }

    if(ebaIds.length > 0) {
      dispatch(fetchEbas({postIds : ebaIds}))
    }
  }, [dispatch, payRateData])

  return (
    <>
    </>
  )
}
